<script lang="ts" setup>
import FacebookIcon from '@/components/svg/FacebookIcon.vue'
import InstagramIcon from '@/components/svg/InstagramIcon.vue'
import WhatsappIcon from '@/components/svg/WhatsappIcon.vue'
import TwitterIcon from '@/components/svg/TwitterIcon.vue'
import MessengerIcon from '@/components/svg/MessengerIcon.vue'
import SnapchatIcon from '@/components/svg/SnapchatIcon.vue'
import HomeCardMaintenance from '@/components/pages/Home/HomeCardMaintenance.vue'
import Button from '@/components/Button.vue'
import PrimeVideoLogo from '@/components/svg/PrimeVideoLogo.vue'

export interface Props {
  title: string
  subtitle?: string
  showIcons?: boolean
  showMaintenance?: boolean
  maintenanceEndTime?: null | string
  help?: string
  buttonText: string
}

withDefaults(defineProps<Props>(), {
  showIcons: false,
  showMaintenance: false,
  maintenanceEndTime: null
})

const emits = defineEmits<{
  (e: 'click', value: Event): void
}>()

const handleClick = (value: Event) => {
  emits('click', value)
}
</script>

<template>
  <div
    class="tc-home__card"
    @click="handleClick"
  >
    <div class="tc-home__card-body">
      <HomeCardMaintenance
        v-if="showMaintenance"
        size="sm"
        :maintenance-end-time="maintenanceEndTime"
      />

      <h3 class="tc-home__card-title">{{ title }}</h3>

      <h4
        v-if="subtitle"
        class="tc-home__card-subtitle"
      >
        {{ subtitle }}
      </h4>

      <div
        v-if="showIcons"
        class="tc-home__card-icons"
      >
        <FacebookIcon />
        <InstagramIcon />
        <WhatsappIcon />
        <TwitterIcon />
        <MessengerIcon />
        <SnapchatIcon />
        <PrimeVideoLogo />
      </div>

      <p
        v-if="help"
        class="tc-home__card-help"
      >
        {{ help }}
      </p>
    </div>

    <Button
      size="sm"
      color="secondary"
      class="tc-home__card-button"
    >
      {{ buttonText }}
    </Button>
  </div>
</template>

<style lang="scss">
.tc-home {
  &__card {
    position: relative;
    border-radius: $border-radius;
    background-color: $white;
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 15%);
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: 100%;
    min-height: 220px;
    color: $black;
    cursor: pointer;
  }

  &__card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px;
    border-radius: $border-radius $border-radius 0 0;
    border: 3px solid $ocean-blue;
    border-bottom: none;
    flex: 1;
  }

  &__card-title {
    font-size: 21px;
    font-weight: 700;
    text-align: center;
    margin: 0 0 6px 0;
  }

  &__card-subtitle {
    font-weight: 400;
    text-align: center;
    font-size: 15px;
    margin: 0 0 8px 0;
  }

  &__card-icons {
    text-align: center;
    margin-bottom: 8px;

    & > img + img {
      margin-left: 8px;
    }
  }

  &__card-help {
    text-align: center;
    font-size: 12px;
    margin: 0;
  }

  &__card-button {
    border-radius: 0 0 $border-radius $border-radius !important;
  }
}
</style>
