import Page from '@/shared/enums/Page'
import useAppStore from '@/stores/app'
import useSeigyoStore from '@/stores/seigyo'
import type { NavigationGuard } from 'vue-router'

const ROUTES_EXCLUDED_FROM_REDIRECTS = [
  Page.Home,
  Page.Error,
  Page.RequestBalance,
  Page.TermsAndConditions,
  Page.TermsAndConditionsDetails
]

const seigyoMaintenanceGuard: NavigationGuard = async (to, from, next) => {
  const appStore = useAppStore()
  const seigyoStore = useSeigyoStore()

  if (!seigyoStore.config && to.name !== Page.Error) {
    try {
      await seigyoStore.fetchConfig()
    } catch (error) {
      next({ name: Page.Error })
      return
    }
  }

  if (seigyoStore.isMaintenance && !ROUTES_EXCLUDED_FROM_REDIRECTS.includes(to.name as Page)) {
    if (appStore.isDefaultChannel) {
      next({ name: Page.Home })
    } else {
      next({ name: Page.Error })
    }
  } else {
    next()
  }
}

export default seigyoMaintenanceGuard
