import useTokenStore from '@/stores/token'
import AdvertisingBannerDevice from '@/shared/enums/AdvertisingBannerDevice'

const getAdvertisingBannerDevice = () => {
  const tokenStore = useTokenStore()

  if (tokenStore.decodedToken?.iOSViewer) {
    return AdvertisingBannerDevice.IOS
  }

  if (tokenStore.decodedToken?.androidViewer) {
    return AdvertisingBannerDevice.ANDROID
  }

  return AdvertisingBannerDevice.OTHER
}

export default getAdvertisingBannerDevice
