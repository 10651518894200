import useAppStore from '@/stores/app'

const POLICY = 'same-origin'
const LINK_ID = 'redirect'

export const redirect = (url: string) => {
  const link = document.createElement('a')

  link.id = LINK_ID
  link.href = url
  link.referrerPolicy = POLICY
  link.rel = POLICY

  document.body.appendChild(link)
  document.getElementById(LINK_ID)?.click()
}

export const redirectPromise = async (url: string) => {
  const appStore = useAppStore()

  appStore.setLoading(true)

  redirect(url)

  try {
    await new Promise((_, reject) => {
      setTimeout(() => {
        reject(new Error(`Cannot redirect to ${url}`))
      }, 15000)
    })
  } catch (error) {
    return Promise.reject(error)
  } finally {
    appStore.setLoading(false)
  }
}
