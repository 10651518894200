import useTokenStore from '@/stores/token'
import { NULL_CAMPAIGN } from '@/shared/constants/campaigns'
import useAppStore from '@/stores/app'

export const getRegenerateTokenUrl = () => {
  const tokenStore = useTokenStore()

  if (tokenStore.campaign !== NULL_CAMPAIGN) {
    return `${import.meta.env.VITE_APP_URL_HTTP}/cc/${tokenStore.campaign}`
  } else {
    return import.meta.env.VITE_APP_URL_HTTP
  }
}

export const regenerateToken = () => {
  window.location.href = getRegenerateTokenUrl()
}

export const regenerateTokenPromise = async () => {
  const appStore = useAppStore()

  appStore.setLoading(true)

  regenerateToken()

  try {
    await new Promise((_, reject) => {
      setTimeout(() => {
        reject(new Error('Cannot regenerate token'))
      }, 15000)
    })
  } catch (error) {
    return Promise.reject(error)
  } finally {
    appStore.setLoading(false)
  }
}
