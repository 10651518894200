<template>
  <hr class="tc-divider" />
</template>

<style>
.tc-divider {
  border: 1px solid #f2f7f9;
  margin: 0;
}
</style>
