import { createApp } from 'vue'
import { setLocale } from 'yup'
import { createPinia } from 'pinia'
import yupLocaleEs from '@/lang/yup.es'

import App from '@/App.vue'
import router from '@/router'

import '@/assets/scss/main.scss'

setLocale(yupLocaleEs)

const app = createApp(App)

app.use(createPinia())
app.use(router)

app.mount('#app')
