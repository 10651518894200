import { regenerateTokenPromise } from '@/shared/utils/regenerateToken'
import useTokenStore from '@/stores/token'

const validateTokenExpiration = () => {
  const tokenStore = useTokenStore()

  if (tokenStore.isValidToken) {
    return Promise.resolve(true)
  }

  return regenerateTokenPromise()
}

export default validateTokenExpiration
