import Page from '@/shared/enums/Page'
import useOfferStore from '@/stores/offer'
import axios from 'axios'
import type { NavigationGuard } from 'vue-router'

const offerStoreLengthGuard: NavigationGuard = async (to, from, next) => {
  const offerStore = useOfferStore()

  if (!offerStore.offer.length) {
    try {
      await offerStore.fetchOffer()
    } catch (error) {
      if (!axios.isAxiosError(error) || error.response?.status !== 404) {
        next({ name: Page.Error })
      }
    }
  }

  next()
}

export default offerStoreLengthGuard
