import Page from '@/shared/enums/Page'
import useTokenStore from '@/stores/token'
import type { NavigationGuard, RouteLocationRaw } from 'vue-router'

const PROTECTED_ROUTES = [
  Page.Balance,
  Page.Packages,
  Page.PackageDetails,
  Page.Orders,
  Page.OrderDetails,
  Page.Help
]

const urlTokenGuard: NavigationGuard = async (to, from, next) => {
  const { t: token, ...query } = to.query

  if (!token) {
    next()
    return
  }

  const tokenStore = useTokenStore()

  tokenStore.setUrlToken(token as string)
  tokenStore.setToken(token as string)

  const homeLocation = {
    ...to,
    name: Page.Home,
    query: { ...query }
  } as RouteLocationRaw

  if (!to.name || to.name === Page.Home) {
    next(homeLocation)
    return
  }

  if (!PROTECTED_ROUTES.includes(to.name as Page)) {
    next({ ...to, query: { ...query } })
    return
  }

  if (!tokenStore.msisdn) {
    next(homeLocation)
    return
  }

  try {
    await tokenStore.fetchBuyPackageToken()

    if (tokenStore.isProfiledToken) {
      next({ ...to, query: { ...query } })
    } else {
      next(homeLocation)
    }
  } catch (error) {
    next({ name: Page.Error })
  }
}

export default urlTokenGuard
