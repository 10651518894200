<template>
  <svg
    id="secured_byhtech_big"
    xmlns="http://www.w3.org/2000/svg"
    width="82"
    height="42"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        y1=".5"
        x2="1"
        y2=".5"
        gradientUnits="objectBoundingBox"
      >
        <stop
          offset="0"
          stop-color="#0a51ff"
        />
        <stop
          offset=".34"
          stop-color="#6e278d"
        />
        <stop
          offset=".67"
          stop-color="#f84003"
        />
        <stop
          offset="1"
          stop-color="#fcaf46"
        />
      </linearGradient>
    </defs>
    <g
      id="Grupo_4479"
      data-name="Grupo 4479"
    >
      <path
        id="Trazado_3958"
        data-name="Trazado 3958"
        d="m5.324 57.431 9.3-9.074 1.134 1.162-9.3 9.074zm16.86-2.118L17.418 50.9a1.312 1.312 0 0 0-1.809.023l-9.561 9.311a.3.3 0 0 1-.429-.006l-3.656-3.747a.3.3 0 0 1 .005-.429l3.66-3.571a.3.3 0 0 1 .421 0l1.559 1.486 1.162-1.135-2.03-1.938a1.309 1.309 0 0 0-1.822.011L.4 55.318a1.315 1.315 0 0 0-.023 1.857L4.885 61.8a1.315 1.315 0 0 0 1.856.024l9.584-9.332a.3.3 0 0 1 .418-.005l3.844 3.56a.3.3 0 0 1 .006.44l-3.842 3.754a.3.3 0 0 1-.427 0l-3.3-3.3-1.162 1.134 3.737 3.74a1.317 1.317 0 0 0 1.847.011l4.757-4.649a1.286 1.286 0 0 0-.025-1.864z"
        transform="translate(0 -39.167)"
        style="fill: url(#linear-gradient)"
      />
      <g
        id="Grupo_4477"
        data-name="Grupo 4477"
        transform="translate(27.147 10.221)"
      >
        <path
          id="Trazado_3959"
          data-name="Trazado 3959"
          fill="#171819"
          d="M213.272 71.881c-3.078 0-4.238-.893-4.238-3v-4.468h-1.311v-1.806h1.311v-2.223h2.717v2.223h4.049v1.805h-4.029v4.276c0 1.045.589 1.387 2.185 1.387a14.345 14.345 0 0 0 1.805-.1v1.653a10.834 10.834 0 0 1-2.489.253z"
          transform="translate(-194.738 -59.092)"
        />
        <path
          id="Trazado_3960"
          data-name="Trazado 3960"
          fill="#171819"
          d="M149.141 66.465v-5.359h-5.815v5.359h-2.812v-12.77h2.793v5.15h5.834v-5.15h2.793v12.77z"
          transform="translate(-140.514 -53.695)"
        />
        <path
          id="Trazado_3961"
          data-name="Trazado 3961"
          fill="#171819"
          d="M258.773 80.689c-4.58 0-5.169-1.52-5.169-3.306v-2.774c0-1.805.513-3.307 5.093-3.307s5.093 1.5 5.093 3.307v2.109h-7.544v.475c0 1.121.437 1.824 2.527 1.824 1.919 0 2.356-.532 2.356-1.254h2.66v.137c.001 1.782-1.026 2.789-5.016 2.789zm2.356-5.834c0-1.178-.266-1.919-2.432-1.919s-2.451.741-2.451 1.919v.345h4.884z"
          transform="translate(-231.755 -67.9)"
        />
        <path
          id="Trazado_3962"
          data-name="Trazado 3962"
          fill="#171819"
          d="M315.643 80.689c-4.58 0-5.093-1.52-5.093-3.306v-2.774c0-1.805.513-3.307 5.093-3.307s5.093 1.5 5.093 3.307v.361h-2.717v-.057c0-1.178-.209-1.919-2.375-1.919s-2.394.741-2.394 1.919v2.166c0 1.178.228 1.919 2.394 1.919s2.375-.741 2.375-1.919v-.133h2.717v.437c0 1.786-.513 3.306-5.093 3.306z"
          transform="translate(-277.699 -67.9)"
        />
        <path
          id="Trazado_3963"
          data-name="Trazado 3963"
          fill="#171819"
          d="M376.145 66.465v-5.8c0-1.311-.475-1.786-2.052-1.786-1.938 0-2.7.551-2.7 2.318v5.264h-2.717V53.695h2.717v4.6c.475-.76 1.577-1.2 3.364-1.2 3.4 0 4.1 1.235 4.1 3.344v6.024z"
          transform="translate(-324.596 -53.695)"
        />
      </g>
      <g
        id="Grupo_4478"
        data-name="Grupo 4478"
        transform="translate(.064)"
      >
        <path
          id="Trazado_3964"
          data-name="Trazado 3964"
          fill="#171819"
          d="M1.372 6.92a1.73 1.73 0 0 1-.771-.6 1.561 1.561 0 0 1-.27-.912v-.259a.078.078 0 0 1 .088-.089h.647a.079.079 0 0 1 .089.089v.213a.893.893 0 0 0 .39.744 1.75 1.75 0 0 0 1.071.294 1.416 1.416 0 0 0 .921-.257.833.833 0 0 0 .31-.673.717.717 0 0 0-.159-.47 1.523 1.523 0 0 0-.465-.359 8.939 8.939 0 0 0-.89-.394 8.91 8.91 0 0 1-1.023-.453 1.889 1.889 0 0 1-.624-.536 1.36 1.36 0 0 1-.248-.837 1.445 1.445 0 0 1 .545-1.2 2.3 2.3 0 0 1 1.474-.43 2.8 2.8 0 0 1 1.151.221 1.771 1.771 0 0 1 .766.62 1.6 1.6 0 0 1 .27.921v.177a.078.078 0 0 1-.089.089h-.663a.078.078 0 0 1-.092-.088V2.6a.956.956 0 0 0-.367-.766 1.541 1.541 0 0 0-1.014-.306 1.4 1.4 0 0 0-.85.226.764.764 0 0 0-.3.651.735.735 0 0 0 .151.478 1.307 1.307 0 0 0 .447.332q.3.146.908.376a10.143 10.143 0 0 1 1.019.456 1.951 1.951 0 0 1 .646.549 1.365 1.365 0 0 1 .257.846 1.5 1.5 0 0 1-.571 1.236 2.455 2.455 0 0 1-1.572.456 3.009 3.009 0 0 1-1.182-.214z"
          transform="translate(-.331 -.791)"
        />
        <path
          id="Trazado_3965"
          data-name="Trazado 3965"
          fill="#171819"
          d="M29.21 12.125a.078.078 0 0 1-.088.089H26.3a.031.031 0 0 0-.035.035 1.529 1.529 0 0 0 .053.461.946.946 0 0 0 .394.554 1.316 1.316 0 0 0 .749.2 1.137 1.137 0 0 0 1.009-.54c.03-.059.068-.068.115-.026l.505.337a.084.084 0 0 1 .026.115 1.719 1.719 0 0 1-.7.629 2.258 2.258 0 0 1-1.032.23 1.979 1.979 0 0 1-1.089-.283 1.725 1.725 0 0 1-.664-.806 3.361 3.361 0 0 1-.213-1.267 3.761 3.761 0 0 1 .1-.983 1.694 1.694 0 0 1 .638-.966 1.9 1.9 0 0 1 1.16-.354 1.808 1.808 0 0 1 1.327.45 2.153 2.153 0 0 1 .54 1.315 7.027 7.027 0 0 1 .027.81zm-2.533-1.652a.941.941 0 0 0-.336.509 2.06 2.06 0 0 0-.08.5.031.031 0 0 0 .035.036h2.028a.031.031 0 0 0 .035-.036 2.594 2.594 0 0 0-.053-.434 1.066 1.066 0 0 0-1.63-.571z"
          transform="translate(-20.565 -7.856)"
        />
        <path
          id="Trazado_3966"
          data-name="Trazado 3966"
          fill="#171819"
          d="M48.433 13.857a1.713 1.713 0 0 1-.656-.926 3.547 3.547 0 0 1-.133-1.072 3.886 3.886 0 0 1 .133-1.072 1.684 1.684 0 0 1 .66-.9 1.954 1.954 0 0 1 1.147-.337 2.017 2.017 0 0 1 1.161.337 1.541 1.541 0 0 1 .646.85 1.244 1.244 0 0 1 .071.292.086.086 0 0 1-.079.089l-.664.1H50.7c-.042 0-.071-.027-.088-.08l-.027-.142a.869.869 0 0 0-.345-.5 1.084 1.084 0 0 0-.664-.2 1.032 1.032 0 0 0-.647.2.931.931 0 0 0-.345.54 3.587 3.587 0 0 0-.08.841 3.539 3.539 0 0 0 .08.833.986.986 0 0 0 .35.558 1.013 1.013 0 0 0 .642.2 1.1 1.1 0 0 0 .668-.2.867.867 0 0 0 .35-.531.021.021 0 0 0 0-.022.02.02 0 0 1 0-.022v-.035a.075.075 0 0 1 .1-.053l.664.106c.047.012.071.035.071.071v.036a.562.562 0 0 1-.044.195 1.5 1.5 0 0 1-.646.868 2.057 2.057 0 0 1-1.161.328 1.932 1.932 0 0 1-1.145-.352z"
          transform="translate(-38.504 -7.856)"
        />
        <path
          id="Trazado_3967"
          data-name="Trazado 3967"
          fill="#171819"
          d="M72.668 9.914h.664a.078.078 0 0 1 .089.089v4.331a.078.078 0 0 1-.089.089h-.664a.078.078 0 0 1-.088-.089v-.328a.02.02 0 0 0-.013-.022c-.009 0-.019 0-.031.013a1.257 1.257 0 0 1-1.089.487 1.9 1.9 0 0 1-.8-.168 1.351 1.351 0 0 1-.811-1.293V10a.078.078 0 0 1 .089-.089h.664a.078.078 0 0 1 .089.089v2.755a1.034 1.034 0 0 0 .244.726.884.884 0 0 0 .686.266.949.949 0 0 0 .709-.27.985.985 0 0 0 .266-.722V10a.078.078 0 0 1 .085-.086z"
          transform="translate(-56.406 -8.151)"
        />
        <path
          id="Trazado_3968"
          data-name="Trazado 3968"
          fill="#171819"
          d="M95.018 9.8a.082.082 0 0 1 .044.106l-.142.664q-.009.08-.106.053a.928.928 0 0 0-.355-.062l-.124.009a.993.993 0 0 0-.687.288.952.952 0 0 0-.27.7v2.586a.078.078 0 0 1-.089.089h-.673a.078.078 0 0 1-.088-.089V9.818a.078.078 0 0 1 .088-.089h.673a.078.078 0 0 1 .089.089v.469c0 .018 0 .028.013.031s.016 0 .022-.013a1.235 1.235 0 0 1 1.089-.62 1.134 1.134 0 0 1 .516.115z"
          transform="translate(-74.716 -7.967)"
        />
        <path
          id="Trazado_3969"
          data-name="Trazado 3969"
          fill="#171819"
          d="M110.037 12.125a.078.078 0 0 1-.088.089h-2.826a.031.031 0 0 0-.035.035 1.536 1.536 0 0 0 .053.461.948.948 0 0 0 .394.554 1.316 1.316 0 0 0 .749.2 1.137 1.137 0 0 0 1.01-.54q.044-.088.115-.026l.505.337a.084.084 0 0 1 .026.115 1.718 1.718 0 0 1-.7.629 2.257 2.257 0 0 1-1.032.23 1.979 1.979 0 0 1-1.089-.283 1.727 1.727 0 0 1-.664-.806 3.365 3.365 0 0 1-.213-1.267 3.751 3.751 0 0 1 .1-.983 1.694 1.694 0 0 1 .638-.966 1.9 1.9 0 0 1 1.16-.354 1.808 1.808 0 0 1 1.33.45 2.154 2.154 0 0 1 .54 1.315 7.069 7.069 0 0 1 .027.81zm-2.537-1.652a.941.941 0 0 0-.337.509 2.079 2.079 0 0 0-.08.5.031.031 0 0 0 .035.036h2.029a.031.031 0 0 0 .035-.036 2.6 2.6 0 0 0-.053-.434 1.066 1.066 0 0 0-1.63-.571z"
          transform="translate(-85.776 -7.856)"
        />
        <path
          id="Trazado_3970"
          data-name="Trazado 3970"
          fill="#171819"
          d="M131.531 1.157h.673a.078.078 0 0 1 .089.089v6.023a.078.078 0 0 1-.089.089h-.673a.078.078 0 0 1-.088-.089v-.292a.03.03 0 0 0-.013-.026c-.009-.006-.019 0-.031.009a1.392 1.392 0 0 1-.461.345 1.347 1.347 0 0 1-.576.124 1.719 1.719 0 0 1-1.059-.314 1.789 1.789 0 0 1-.616-.89 3.373 3.373 0 0 1-.168-1.134 3.6 3.6 0 0 1 .142-1.072 1.806 1.806 0 0 1 .611-.912 1.733 1.733 0 0 1 1.089-.328 1.4 1.4 0 0 1 .571.12 1.33 1.33 0 0 1 .465.341c.012.012.022.015.031.009a.03.03 0 0 0 .013-.026V1.246a.078.078 0 0 1 .09-.089zm-.107 3.943a2.338 2.338 0 0 0-.221-1.134.819.819 0 0 0-.753-.452.858.858 0 0 0-.788.425 2.143 2.143 0 0 0-.283 1.169 2.338 2.338 0 0 0 .222 1.1.879.879 0 0 0 .841.487.845.845 0 0 0 .779-.478 2.39 2.39 0 0 0 .203-1.117z"
          transform="translate(-103.753 -1.086)"
        />
        <path
          id="Trazado_3971"
          data-name="Trazado 3971"
          fill="#171819"
          d="M166.718 5.09a3.373 3.373 0 0 1-.168 1.134 1.791 1.791 0 0 1-.616.89 1.719 1.719 0 0 1-1.059.314 1.347 1.347 0 0 1-.575-.128 1.388 1.388 0 0 1-.46-.345c-.012-.012-.022-.015-.031-.009a.03.03 0 0 0-.013.026v.292a.078.078 0 0 1-.088.089h-.673a.078.078 0 0 1-.088-.089V1.246a.078.078 0 0 1 .088-.089h.673a.078.078 0 0 1 .088.089v1.975a.029.029 0 0 0 .013.026c.009.006.019 0 .031-.009a1.325 1.325 0 0 1 .46-.338 1.393 1.393 0 0 1 .571-.12 1.732 1.732 0 0 1 1.089.328 1.8 1.8 0 0 1 .611.912 3.6 3.6 0 0 1 .147 1.07zm-.859.018a2.143 2.143 0 0 0-.283-1.169.858.858 0 0 0-.788-.425.819.819 0 0 0-.753.452A2.32 2.32 0 0 0 163.8 5.1a2.372 2.372 0 0 0 .213 1.116.845.845 0 0 0 .779.478.88.88 0 0 0 .842-.487 2.341 2.341 0 0 0 .225-1.099z"
          transform="translate(-131.528 -1.086)"
        />
        <path
          id="Trazado_3972"
          data-name="Trazado 3972"
          fill="#171819"
          d="M183.348 16.185v-.611a.078.078 0 0 1 .088-.088h.018a1.7 1.7 0 0 0 .558-.089.69.69 0 0 0 .337-.3 2.747 2.747 0 0 0 .257-.651c.006-.006.006-.024 0-.053l-1.479-4.385-.009-.035c0-.041.027-.062.08-.062h.718a.1.1 0 0 1 .106.071l1 3.339a.029.029 0 0 0 .053 0l.992-3.339a.1.1 0 0 1 .106-.071h.7c.065 0 .089.033.071.1l-1.612 4.721a3.794 3.794 0 0 1-.408.908 1.111 1.111 0 0 1-.531.421 2.7 2.7 0 0 1-.921.124h-.124z"
          transform="translate(-147.803 -8.151)"
        />
      </g>
    </g>
  </svg>
</template>
