import axios, { type RawAxiosRequestHeaders } from 'axios'
import validateTokenExpiration from '@/shared/utils/validateTokenExpiration'
import useTokenStore from '@/stores/token'

const baseURL = import.meta.env.VITE_APP_URL_HTTPS
const timeout = 20000

const request = axios.create({
  baseURL,
  timeout
})

request.interceptors.request.use(
  // @ts-ignore-line
  async (request) => {
    await validateTokenExpiration()

    const tokenStore = useTokenStore()

    // @ts-ignore-line
    const oldHeaders = (request?.headers || {}) as RawAxiosRequestHeaders

    // @ts-ignore-line
    const newHeaders = {
      ...(request?.headers || {}),
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      Authorization: tokenStore.token ? `Bearer ${tokenStore.token}` : ''
      // @ts-ignore-line
    } as RawAxiosRequestHeaders

    return {
      ...request,
      headers: {
        ...oldHeaders,
        ...newHeaders
      }
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default request
