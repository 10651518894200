import urlTokenGuard from '@/router/guards/url-token'
import localStorageTokenGuard from '@/router/guards/local-storage-token'
import tokenExpirationGuard from '@/router/guards/token-expiration'
import seigyoMaintenanceGuard from '@/router/guards/seigyo-maintenance'
import packageRedirectQueryGuard from '@/router/guards/package-redirect-query'
import packageDetailsExternalIDParamGuard from '@/router/guards/package-details-external-id-param'
import packageDetailsPaymentMethodGuard from '@/router/guards/package-details-payment-method-query'
import Page from '@/shared/enums/Page'
import type { NavigationGuard } from 'vue-router'
import protectedRouteGuard from '@/router/guards/protected-route'
import campaignGuard from '@/router/guards/campaign'
import localStorageUrlTokenGuard from '@/router/guards/local-storage-url-token'
import zeroCostGuard from '@/router/guards/zero-cost'
import mirrorChannelGuard from '@/router/guards/mirror-channel'
import offerStoreLengthGuard from '@/router/guards/offer-store-length'
import seigyoInvoicesMaintenanceGuard from '@/router/guards/seigyo-invoices-maintenance'
import localStorageSkipPackagesGuard from '@/router/guards/local-storage-packages-home'
import localStorageSkipHomeGuard from '@/router/guards/local-storage-skip-home'

export const DEFAULT_GUARDS: NavigationGuard[] = [
  urlTokenGuard,
  localStorageUrlTokenGuard,
  localStorageTokenGuard,
  tokenExpirationGuard,
  seigyoMaintenanceGuard,
  seigyoInvoicesMaintenanceGuard,
  mirrorChannelGuard
]

export const ROUTES_GUARDS: Record<Page, NavigationGuard[]> = {
  [Page.Home]: [campaignGuard, localStorageSkipHomeGuard],
  [Page.SendBalance]: [],
  [Page.RequestBalance]: [],
  [Page.Balance]: [],
  [Page.Packages]: [
    protectedRouteGuard,
    offerStoreLengthGuard,
    localStorageSkipPackagesGuard,
    packageRedirectQueryGuard
  ],
  [Page.PackageDetails]: [
    protectedRouteGuard,
    offerStoreLengthGuard,
    packageDetailsExternalIDParamGuard,
    packageDetailsPaymentMethodGuard
  ],
  [Page.Orders]: [protectedRouteGuard, zeroCostGuard],
  [Page.OrderDetails]: [protectedRouteGuard],
  [Page.Help]: [],
  [Page.TermsAndConditions]: [],
  [Page.TermsAndConditionsDetails]: [],
  [Page.Error]: []
}
