import useTokenStore from '@/stores/token'
import { NULL_CAMPAIGN } from '@/shared/constants/campaigns'
import useAppStore from '@/stores/app'

export const getRedirectToInvoicesUrl = () => {
  const tokenStore = useTokenStore()

  if (tokenStore.campaign !== NULL_CAMPAIGN) {
    return `${import.meta.env.VITE_APP_URL_HTTP}/ps/cc/${tokenStore.campaign}`
  } else {
    return `${import.meta.env.VITE_APP_URL_HTTP}/ps`
  }
}
export const getRedirectToInvoicesUrlWithToken = () => {
  const tokenStore = useTokenStore()

  return `${import.meta.env.VITE_APP_URL_HTTPS}/ps/home?t=${tokenStore.token}`
}

export const redirectToInvoices = () => {
  window.location.href = getRedirectToInvoicesUrl()
}

export const redirectToInvoicesWithToken = () => {
  window.location.href = getRedirectToInvoicesUrlWithToken()
}

export const redirectToInvoicesPromise = async () => {
  const appStore = useAppStore()

  appStore.setLoading(true)

  redirectToInvoices()

  try {
    await new Promise((_, reject) => {
      setTimeout(() => {
        reject(new Error('Cannot redirect'))
      }, 15000)
    })
  } catch (error) {
    return Promise.reject(error)
  } finally {
    appStore.setLoading(false)
  }
}

export const redirectToInvoicesWithTokenPromise = async () => {
  const appStore = useAppStore()

  appStore.setLoading(true)

  redirectToInvoicesWithToken()

  try {
    await new Promise((_, reject) => {
      setTimeout(() => {
        reject(new Error('Cannot redirect'))
      }, 15000)
    })
  } catch (error) {
    return Promise.reject(error)
  } finally {
    appStore.setLoading(false)
  }
}
