import type { Offer } from '@/shared/interfaces/Offer'
import type { Package } from '@/shared/interfaces/Package'
import request from '@/shared/utils/request'
import type { AxiosPromise } from 'axios'

export const getOffer = (): AxiosPromise<Offer[]> => {
  return request({
    url: '/eiko-offer/packages',
    method: 'GET',
    params: { isAtlasTheme: true }
  })
}

export const getPackage = (id: string): AxiosPromise<Package> => {
  return request({
    url: `/eiko-offer/packages/${id}`,
    method: 'GET'
  })
}

export const getCampaignPackage = (campaign: string): AxiosPromise<Package> => {
  return request({
    url: `/eiko-offer/packages/getCCPackage/${campaign}`,
    method: 'GET'
  })
}
