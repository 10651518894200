<template>
  <div class="tc-backdrop">
    <slot></slot>
  </div>
</template>

<style lang="scss">
.tc-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: $black, $alpha: 0.7);
}
</style>
