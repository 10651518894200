import useTokenStore from '@/stores/token'
import PaymentMethod from '@/shared/enums/PaymentMethod'
import { startOrder, startTransaction } from '@/api/orders'
import useAppStore from '@/stores/app'

const redirectToSanval = async (packageId: string) => {
  const appStore = useAppStore()
  const tokenStore = useTokenStore()
  const payments = JSON.parse(tokenStore.decodedToken?.payments || '{}')
  const paymentId = payments[PaymentMethod.CreditCard]

  try {
    const orderResponse = await startOrder({ paymentId, packageId })
    const transactionResponse = await startTransaction({ folio: orderResponse.data.folio })

    appStore.setLoading(true)

    window.location.href = transactionResponse.data.redirectUrl

    await new Promise((resolve) => {
      setTimeout(resolve, 6000)
    })
  } catch (error) {
    return Promise.reject(error)
  } finally {
    appStore.setLoading(false)
  }
}

export default redirectToSanval
