<script lang="ts" setup>
import Card from '@/components/Card.vue'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import type { Size } from '@/shared/types/Size'

export interface Props {
  size?: Size
  showClose?: boolean
}

withDefaults(defineProps<Props>(), {
  size: 'xxs',
  showClose: true
})

const emits = defineEmits<{
  (e: 'close'): void
}>()
</script>

<template>
  <Card
    :size="size"
    class="tc-modal__card"
  >
    <CloseIcon
      v-if="showClose"
      class="tc-modal__close"
      @click="emits('close')"
    />

    <slot></slot>
  </Card>
</template>

<style lang="scss">
.tc-modal {
  &__card .tc-card__body {
    overflow-y: auto;
  }

  &__close {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 32px;
    z-index: 1;
    height: 32px;
    padding: 4px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
}
</style>
