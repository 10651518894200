import { regenerateTokenPromise } from '@/shared/utils/regenerateToken'
import useAppStore from '@/stores/app'
import useTokenStore from '@/stores/token'
import type { NavigationGuard } from 'vue-router'

const localStorageUrlTokenGuard: NavigationGuard = async (to, from, next) => {
  const tokenStore = useTokenStore()
  const appStore = useAppStore()

  if (appStore.isDefaultChannel && !tokenStore.urlToken) {
    const urlToken = localStorage.getItem(import.meta.env.VITE_APP_URL_TOKEN_KEY)

    if (urlToken) {
      tokenStore.setUrlToken(urlToken as string)
    } else {
      await regenerateTokenPromise()
    }
  }

  next()
}

export default localStorageUrlTokenGuard
