import CheckoutStep from '@/shared/enums/CheckoutStep'
import Page from '@/shared/enums/Page'
import PaymentMethod from '@/shared/enums/PaymentMethod'
import getPaymentId from '@/shared/utils/getPaymentId'
import useCheckoutStore from '@/stores/checkout'
import useTokenStore from '@/stores/token'
import type { NavigationGuard } from 'vue-router'

const packageRedirectQueryGuard: NavigationGuard = async (to, from, next) => {
  const { packageID, paymentType, ...query } = to.query

  if (!packageID && !paymentType) {
    next()
    return
  }

  if (!packageID || !paymentType) {
    next({ ...to, query: {} })
    return
  }

  const checkoutStore = useCheckoutStore()
  checkoutStore.setPackageById(packageID as string)

  if (!checkoutStore.package || !checkoutStore.package.paymentMethodsAllowed.length) {
    next({ ...to, query: { ...query } })
    return
  }

  const paymentMethod = PaymentMethod[paymentType as keyof typeof PaymentMethod]
  const paymentID = getPaymentId(paymentMethod)

  if (!checkoutStore.package.paymentMethodsAllowed.includes(paymentMethod) || !paymentID) {
    next({ ...to, query: { ...query } })
    return
  }

  checkoutStore.setStep(CheckoutStep.CONFIRM)

  const tokenStore = useTokenStore()

  try {
    await checkoutStore.fetchSwitchOrder({
      targetMsisdn: tokenStore.decodedToken?.msisdn as string,
      packageID: packageID as string,
      paymentID
    })

    next({
      name: Page.PackageDetails,
      params: {
        externalID: checkoutStore.package.externalID
      }
    })
  } catch (error) {
    next({ name: Page.Error })
  }
}

export default packageRedirectQueryGuard
