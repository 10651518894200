<script lang="ts" setup>
import { ref } from 'vue'
import useAdsStore from '@/stores/ads'
import HybridCampaignModalButton from '@/components/pages/Home/HybridCampaignModal/HybridCampaignModalButton.vue'
import HybridCampaignModalContent from '@/components/pages/Home/HybridCampaignModal/HybridCampaignModalContent.vue'
import HybridCampaignModalSuccess from '@/components/pages/Home/HybridCampaignModal/HybridCampaignModalSuccess.vue'

export type HybridCampaignModalComponent = 'button' | 'content' | 'success'

const adsStore = useAdsStore()
const component = ref<HybridCampaignModalComponent>('button')

const handleClickClose = () => {
  adsStore.setShowHybridCampaignAdd(false)
}

const handleClickNext = () => {
  switch (component.value) {
    case 'button':
      component.value = 'content'
      break
    case 'content':
      component.value = 'success'
      break
    default:
      handleClickClose()
      break
  }
}
</script>

<template>
  <template v-if="adsStore.showHybridCampaignAdd">
    <HybridCampaignModalButton
      v-if="component === 'button'"
      @next="handleClickNext"
      @close="handleClickClose"
    />

    <HybridCampaignModalContent
      v-else-if="component === 'content'"
      @next="handleClickNext"
      @close="handleClickClose"
    />

    <HybridCampaignModalSuccess
      v-else
      @close="handleClickClose"
    />
  </template>
</template>
