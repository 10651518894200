<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="mc-nuevo"
    width="49"
    height="31"
    viewBox="0 0 49 31"
  >
    <g id="mc_3">
      <path
        id="Path_3717"
        d="M151.988 72.764v-.439h-.188V72.2h.5v.125h-.188v.439zm.94 0v-.376l-.125.345h-.125l-.125-.345v.376h-.125V72.2h.188l.125.345.125-.345h.188v.564z"
        fill="#f59e00"
        transform="translate(-104.28 -49.582)"
      />
      <path
        id="Path_3722"
        fill="#ea610a"
        d="M67.808 31.515a15.021 15.021 0 0 0 3.227-8.866V21.8A14.863 14.863 0 0 0 65.3 10.4a15 15 0 0 0-5.7 11.779 15.059 15.059 0 0 0 5.7 11.81 14.421 14.421 0 0 0 2.508-2.474z"
        transform="translate(-40.964 -7.142)"
      />
      <path
        id="Path_3723"
        fill="#e20d17"
        d="M18.748 15.093a15 15 0 0 1 5.7-11.779A15.035 15.035 0 1 0 5.841 26.935a15.281 15.281 0 0 0 9.273 3.227A14.914 14.914 0 0 0 24.45 26.9a15.059 15.059 0 0 1-5.702-11.807z"
        transform="translate(-.112 -.056)"
      />
      <path
        id="Path_3725"
        d="M96.44 3.227a15.016 15.016 0 0 0-18.64.031 14.962 14.962 0 0 1 5.733 11.4v.846a15.1 15.1 0 0 1-3.227 8.866 15.411 15.411 0 0 1-2.475 2.475A15.035 15.035 0 0 0 96.44 3.227z"
        fill="#f59e00"
        transform="translate(-53.462)"
      />
    </g>
  </svg>
</template>
