<script lang="ts" setup>
import Modal from '@/components/Modal/index.vue'
import { computed, onBeforeMount, ref } from 'vue'
import useSeigyoStore from '@/stores/seigyo'
import redirectToSanval from '@/shared/utils/redirectToSanval'
import useOfferStore from '@/stores/offer'
import useTokenStore from '@/stores/token'
import getAdvertisingBannerDevice from '@/shared/utils/getAdvertisingBannerDevice'
import Profile from '@/shared/enums/Profile'
import { useRouter } from 'vue-router'
import { redirectPromise } from '@/shared/utils/redirectToUrl'

const router = useRouter()
const tokenStore = useTokenStore()
const offerStore = useOfferStore()
const seigyoStore = useSeigyoStore()

const open = ref(false)

const allProfilesSelected = computed(() =>
  Object.values(Profile)
    .filter((item) => typeof item === 'number' && item !== Profile.NONE)
    .some((item) => seigyoStore.homeModalBanner?.Profiles.includes(item as Profile))
)

const handleClose = () => {
  open.value = false
}

const handleClick = async () => {
  try {
    if (!tokenStore.isProfiledToken) {
      await tokenStore.fetchBuyPackageToken()
    }

    if (seigyoStore.homeModalBanner?.Package) {
      await offerStore.fetchOffer()

      const packageList = seigyoStore.homeModalBanner?.Package.split(',')
      const packageItem = offerStore.packages.find((item) => packageList?.includes(item.externalID))

      if (packageItem) {
        await redirectToSanval(packageItem.id)
      }
    } else if (seigyoStore.homeModalBanner?.Link) {
      if (seigyoStore.homeModalBanner.Link.startsWith('/')) {
        await router.push(seigyoStore.homeModalBanner.Link)
      } else {
        await redirectPromise(seigyoStore.homeModalBanner.Link)
      }
    }
  } catch (error) {
    //
  }

  open.value = false
}

onBeforeMount(async () => {
  if (
    !seigyoStore.homeModalBanner ||
    (seigyoStore.homeModalBanner.AreaCode &&
      seigyoStore.homeModalBanner.AreaCode !== tokenStore.msisdnAreaCode) ||
    (seigyoStore.homeModalBanner.Devices.length &&
      !seigyoStore.homeModalBanner.Devices.includes(getAdvertisingBannerDevice()))
  ) {
    return
  }

  if (allProfilesSelected.value) {
    open.value = true
    return
  }

  if (!tokenStore.isProfiledToken) {
    await tokenStore.fetchBuyPackageToken()
  }

  if (!seigyoStore.homeModalBanner.Profiles.includes(tokenStore.profileType)) {
    return
  }

  if (!seigyoStore.homeModalBanner?.Package) {
    open.value = true
    return
  }

  await offerStore.fetchOffer()

  const packageList = seigyoStore.homeModalBanner.Package.split(',')
  const packageItem = offerStore.packages.find((item) => packageList?.includes(item.externalID))

  open.value = Boolean(packageItem)
})
</script>

<template>
  <Modal
    v-model:open="open"
    type="card"
    :before-close="handleClose"
    class="tc-home-modal"
  >
    <img
      :src="seigyoStore.homeModalBanner?.Location"
      :alt="seigyoStore.homeModalBanner?.Placeholder"
      class="tc-home-modal__banner"
      @click="handleClick"
    />
  </Modal>
</template>

<style lang="scss">
.tc-home-modal {
  .tc-card {
    padding: 16px;
  }

  &__banner {
    width: 100%;
    max-width: 400px;
  }
}
</style>
