import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/router/routes'
import { DEFAULT_GUARDS } from '@/router/guards'

/*
 * createWebHistory function documentation:
 * https://router.vuejs.org/guide/essentials/history-mode.html#html5-mode
 */

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

DEFAULT_GUARDS.forEach((guard) => router.beforeEach(guard))

export default router
