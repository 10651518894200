<template>
  <div class="tc-spinner"></div>
</template>

<style lang="scss">
.tc-spinner {
  border: 10px solid #19a2b91a;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border-left-color: #00b5ef;
  border-right-color: #00b5ef;
  animation: spin 1.1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
