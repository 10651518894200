<script lang="ts" setup>
import Page from '@/shared/enums/Page'
import SaldosServiciosLogo from '@/components/svg/SaldosServiciosLogo.vue'
import { useRoute, useRouter } from 'vue-router'
import { computed, ref, watch } from 'vue'
import useTokenStore from '@/stores/token'
import HomeIcon from '@/components/svg/HomeIcon.vue'
import LeftArrowIcon from '@/components/svg/LeftArrowIcon.vue'

const HOME_URL = import.meta.env.VITE_APP_URL_HTTP

const MSISDN_HIDDEN_ROUTES = [Page.Home, Page.Error, Page.SendBalance, Page.RequestBalance]

const route = useRoute()
const router = useRouter()
const tokenStore = useTokenStore()

const history = ref<Page[]>([])

const showHomeIcon = computed(() => {
  return route.name && (route.name === Page.Packages || route.name === Page.Help)
})

const showMsisdn = computed(() => {
  return (
    route.name &&
    tokenStore.decodedToken?.msisdn &&
    !MSISDN_HIDDEN_ROUTES.includes(route.name as Page)
  )
})

const handleClickBack = () => {
  router.push({ name: Page.Packages })
}

watch(
  () => route.name,
  () => {
    history.value = [...history.value, route.name as Page]
  }
)
</script>

<template>
  <div class="tc-mirror-header">
    <div class="tc-mirror-header__container">
      <a
        v-if="showHomeIcon"
        :href="HOME_URL"
        aria-label="Ir a inicio"
        class="tc-mirror-header__home-icon"
      >
        <HomeIcon />
      </a>

      <LeftArrowIcon
        v-else
        class="tc-mirror-header__back-arrow"
        @click="handleClickBack"
      />

      <a
        :href="HOME_URL"
        aria-label="Ir a inicio"
      >
        <SaldosServiciosLogo class="tc-mirror-header__logo" />
      </a>

      <span
        v-if="showMsisdn"
        data-testid="qa-packages-welcome"
        class="tc-mirror-header__msisdn"
      >
        Bienvenido {{ tokenStore.decodedToken?.msisdn?.slice(2, 12) }}
      </span>
    </div>

    <div class="tc-mirror-header__divider"></div>
  </div>
</template>

<style lang="scss">
.tc-mirror-header {
  background-color: $white;
  height: 60px;
  display: flex;
  position: relative;

  &__container {
    width: 100%;
    max-width: $screen-sm;
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__home-icon {
    position: absolute;
    left: 8px;
    display: flex;
    cursor: pointer;
  }

  &__back-arrow {
    position: absolute;
    display: flex;
    fill: $ocean-blue;
    left: 0;
  }

  @media (max-width: $screen-sm) {
    &__back-arrow {
      left: 10px;
    }
  }

  &__logo {
    height: 30px;
    width: auto;
  }

  &__msisdn {
    color: $ocean-blue;
    font-size: 10px;
  }

  &__divider {
    width: 100%;
    height: 2px;
    background: linear-gradient(to left, #46afe9, #487ac2 46%, #254aa5);
    position: absolute;
    bottom: 0;
  }
}
</style>
