import Channel from '@/shared/enums/Channel'
import { defineStore } from 'pinia'
import { computed, ref, type Ref } from 'vue'

const useAppStore = defineStore('app', () => {
  const loading = ref(false)
  const channel: Ref<Channel> = ref(import.meta.env.VITE_APP_CHANNEL)

  const isDefaultChannel = computed(() => {
    return channel.value === Channel.DEFAULT
  })

  const isMirrorChannel = computed(() => {
    return channel.value === Channel.MIRROR
  })

  const setLoading = (value: boolean) => {
    loading.value = value
  }

  const setChannel = (value: Channel) => {
    channel.value = value
  }

  return {
    loading,
    channel,
    isDefaultChannel,
    isMirrorChannel,
    setLoading,
    setChannel
  }
})

export default useAppStore
