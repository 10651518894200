import { jwtDecode } from 'jwt-decode'

const getDecodedToken = <T extends unknown>(token: string): null | T => {
  try {
    return jwtDecode(token)
  } catch (error) {
    return null
  }
}

export default getDecodedToken
