<script lang="ts" setup>
import Page from '@/shared/enums/Page'
import TelcelLogoWhite from '@/components/svg/TelcelLogoWhite.vue'
import LeftArrowIcon from '@/components/svg/LeftArrowIcon.vue'
import { useRoute, useRouter } from 'vue-router'
import { computed, ref, watch } from 'vue'
import useTokenStore from '@/stores/token'

const BACK_ARROW_HIDDEN_ROUTES = [Page.Home, Page.Error]

const MSISDN_HIDDEN_ROUTES = [Page.Home, Page.Error, Page.SendBalance, Page.RequestBalance]

const route = useRoute()
const router = useRouter()
const tokenStore = useTokenStore()

const history = ref<Page[]>([])

const showBackArrow = computed(() => {
  return route.name && !BACK_ARROW_HIDDEN_ROUTES.includes(route.name as Page)
})

const showMsisdn = computed(() => {
  return (
    route.name &&
    tokenStore.decodedToken?.msisdn &&
    !MSISDN_HIDDEN_ROUTES.includes(route.name as Page)
  )
})

const handleClick = () => {
  if (history.value.length > 1) {
    router.back()
  } else {
    router.push({ name: Page.Home })
  }

  history.value = history.value.slice(0, -2)
}

watch(
  () => route.name,
  () => {
    history.value = [...history.value, route.name as Page]
  }
)
</script>

<template>
  <div class="tc-default-header">
    <div class="tc-default-header__container">
      <LeftArrowIcon
        v-if="showBackArrow"
        class="tc-default-header__back-arrow"
        @click="handleClick"
      />

      <div class="tc-default-header__body">
        <RouterLink
          :to="{ name: Page.Home }"
          aria-label="Ir a inicio"
        >
          <TelcelLogoWhite class="tc-default-header__logo" />
        </RouterLink>

        <span
          v-if="showMsisdn"
          data-testid="qa-packages-welcome"
          class="tc-default-header__msisdn"
        >
          Bienvenido {{ tokenStore.decodedToken?.msisdn?.slice(2, 12) }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.tc-default-header {
  background-color: $ocean-blue;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    width: 100%;
    max-width: $screen-sm;
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__msisdn {
    color: $white;
    font-size: 10px;
  }

  &__back-arrow {
    position: absolute;
    display: flex;
    fill: $white;
    left: 0;
  }

  @media (max-width: $screen-sm) {
    &__back-arrow {
      left: 10px;
    }
  }

  &__logo {
    height: 28px;
    width: auto;
    fill: #fff;
  }
}
</style>
