import { getCampaignPackage } from '@/api/offer'
import { LOCAL_STORAGE_SKIP_PACKAGES_KEY } from '@/shared/constants/general'
import Page from '@/shared/enums/Page'
import useTokenStore from '@/stores/token'
import type { NavigationGuard, NavigationGuardNext } from 'vue-router'

const redirectToPackageDetailsFlow = async (next: NavigationGuardNext) => {
  const tokenStore = useTokenStore()

  try {
    const response = await getCampaignPackage(tokenStore.campaign)

    next({
      name: Page.PackageDetails,
      params: { externalID: response.data.externalID }
    })
  } catch (error) {
    next()
  }
}

const localStorageSkipPackagesGuard: NavigationGuard = async (to, from, next) => {
  const redirectPage = localStorage.getItem(LOCAL_STORAGE_SKIP_PACKAGES_KEY)

  localStorage.removeItem(LOCAL_STORAGE_SKIP_PACKAGES_KEY)

  switch (redirectPage) {
    case Page.PackageDetails:
      await redirectToPackageDetailsFlow(next)
      break
    default:
      next()
  }
}

export default localStorageSkipPackagesGuard
