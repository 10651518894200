import Channel from '@/shared/enums/Channel'
import Page from '@/shared/enums/Page'
import useAppStore from '@/stores/app'
import useSeigyoStore from '@/stores/seigyo'
import type { NavigationGuard } from 'vue-router'

const seigyoInvoicesMaintenanceGuard: NavigationGuard = async (to, from, next) => {
  const appStore = useAppStore()

  if (appStore.channel === Channel.DEFAULT) {
    const seigyoStore = useSeigyoStore()

    if (!seigyoStore.invoicesConfig && to.name !== Page.Error) {
      try {
        await seigyoStore.fetchInvoicesConfig()
      } catch (error) {
        next({ name: Page.Error })
        return
      }
    }
  }

  next()
}

export default seigyoInvoicesMaintenanceGuard
