import type { LocaleObject } from 'yup'

const yupLocaleEs: LocaleObject = {
  mixed: {
    required: 'El campo es requerido'
  },
  string: {
    length: 'El campo debe tener exactamente ${length} caracteres',
    min: 'El campo debe tener al menos ${min} caracteres',
    max: 'El campo debe tener como máximo ${max} caracteres',
    matches: 'El campo debe coincidir con lo siguiente: "${regex}"',
    email: 'El campo debe ser un correo electrónico válido',
    url: 'El campo debe ser una URL válida',
    uuid: 'El campo debe ser un UUID válido',
    trim: 'El campo debe ser una cadena recortada',
    lowercase: 'El campo debe ser una cadena en minúsculas',
    uppercase: 'El campo debe ser una cadena en mayúsculas'
  }
}

export default yupLocaleEs
