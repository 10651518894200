import type { OrderResponse } from '@/shared/interfaces/OrderResponse'
import type { TransactionResponse } from '@/shared/interfaces/TransactionResponse'
import type { OrderPayload } from '@/shared/interfaces/OrderPayload'
import type { TransactionPayload } from '@/shared/interfaces/TransactionPayload'
import type { SwitchOrderPayload } from '@/shared/interfaces/SwitchOrderPayload'
import type { SwitchOrderResponse } from '@/shared/interfaces/SwitchOrderResponse'
import type { ConfirmOrderPayload } from '@/shared/interfaces/ConfirmOrderPayload'
import request from '@/shared/utils/request'
import type { AxiosPromise } from 'axios'
import type { Order } from '@/shared/interfaces/Order'
import type { OrderResume } from '@/shared/interfaces/OrderResume'
import type { GetOrdersPayload } from '@/shared/interfaces/GetOrdersPayload'

export const startOrder = (data: OrderPayload): AxiosPromise<OrderResponse> => {
  return request({
    url: '/eiko-order/orders/beginOrder',
    method: 'POST',
    data
  })
}

export const startTransaction = (data: TransactionPayload): AxiosPromise<TransactionResponse> => {
  return request({
    url: '/eiko-order/sanval/beginTransaction',
    method: 'POST',
    data
  })
}

export const switchOrder = (data: SwitchOrderPayload): AxiosPromise<SwitchOrderResponse> => {
  return request({
    url: '/eiko-order/orders/beginSwitchedOrder',
    method: 'POST',
    data
  })
}

export const confirmInvoiceOrder = (data: ConfirmOrderPayload) => {
  return request({
    url: `/eiko-order/invoice/confirmPurchase/${data.folio}`,
    method: 'POST',
    data
  })
}

export const confirmPhoneCreditOrder = (data: ConfirmOrderPayload) => {
  return request({
    url: '/eiko-order/phoneCredit/confirmPurchase',
    method: 'POST',
    data
  })
}

export const getOrders = (params: GetOrdersPayload = {}): AxiosPromise<OrderResume[]> => {
  return request({
    url: '/eiko-order/orders',
    method: 'GET',
    params
  })
}

export const getOrder = (folio: string, loader = true): AxiosPromise<Order> => {
  return request(
    {
      url: `/eiko-order/orders/${folio}`,
      method: 'GET'
    },
    loader
  )
}

export const cancelOrderRecurrence = () => {
  return request({
    url: '/eiko-order/telcelRecurrencePurchase/cancel',
    method: 'DELETE'
  })
}
