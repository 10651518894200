import type PaymentMethod from '@/shared/enums/PaymentMethod'
import useTokenStore from '@/stores/token'

type PaymentIds = Record<PaymentMethod, string>

const getPaymentId = (paymentMethod: PaymentMethod) => {
  const tokenStore = useTokenStore()
  const paymentIDs = JSON.parse(tokenStore.decodedToken?.payments || '{}') as Partial<PaymentIds>

  return paymentIDs[paymentMethod]
}

export default getPaymentId
