import Page from '@/shared/enums/Page'
import useAppStore from '@/stores/app'
import useTokenStore from '@/stores/token'
import type { NavigationGuard } from 'vue-router'

const PROTECTED_PAGES = [Page.Home, Page.Balance, Page.SendBalance, Page.RequestBalance]

const mirrorChannelGuard: NavigationGuard = async (to, from, next) => {
  const appStore = useAppStore()
  const tokenStore = useTokenStore()

  if (!appStore.isMirrorChannel) {
    next()
    return
  }

  if (!tokenStore.isProfiledToken) {
    next({ name: Page.Error })
    return
  }

  if (PROTECTED_PAGES.some((e) => e === to.name)) {
    next({ name: Page.Packages })
    return
  }

  next()
}

export default mirrorChannelGuard
