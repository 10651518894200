<script lang="ts" setup>
import HomeFooter from '@/components/pages/Home/HomeFooter.vue'
import Divider from '@/components/Divider.vue'
import HomeCardsGrid from '@/components/pages/Home/HomeCardsGrid.vue'
import Page from '@/shared/enums/Page'
import Button from '@/components/Button.vue'
import HomeModal from '@/components/pages/Home/HomeModal.vue'
import HybridCampaignModal from '@/components/pages/Home/HybridCampaignModal/index.vue'
import useSeigyoStore from '@/stores/seigyo'

const seigyoStore = useSeigyoStore()
</script>

<template>
  <div class="tc-home">
    <h2 class="tc-home__title">Elige la opción de tu preferencia</h2>

    <Divider class="tc-home__divider" />

    <HomeCardsGrid />

    <RouterLink
      v-if="!seigyoStore.isMaintenance"
      :to="{ name: Page.Balance }"
    >
      <Button
        size="lg"
        color="tertiary"
      >
        Mi Saldo Actual
      </Button>
    </RouterLink>

    <HomeFooter />
  </div>

  <HomeModal />
  <HybridCampaignModal />
</template>

<style lang="scss">
.tc-home {
  padding: 0 20px;

  &__title {
    font-size: 20px;
    font-weight: 400;
    color: #2c2c2c;
    text-align: center;
  }

  &__divider {
    margin: 10px 0 15px;
  }

  &__balance-link {
    display: block;
  }
}
</style>
