<script lang="ts" generic="T" setup>
import useAppStore from '@/stores/app'
import type { ButtonColor } from '@/shared/types/ButtonColor'

export type ButtonSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg'

export type ButtonShape = 'round' | 'square'

export interface Props {
  size?: ButtonSize
  color?: ButtonColor
  shape?: ButtonShape
  disabled?: boolean
  dataTestid?: string
}

const appStore = useAppStore()

withDefaults(defineProps<Props>(), {
  size: 'md',
  color: 'primary',
  shape: 'round',
  disabled: false
})
</script>

<template>
  <button
    :data-testid="dataTestid"
    :class="[
      'tc-button',
      `tc-button__${size}`,
      `tc-button__${color}`,
      `tc-button__${shape}`,
      appStore.isMirrorChannel ? 'tc-button__mirror' : ''
    ]"
  >
    <slot></slot>
  </button>
</template>

<style lang="scss">
.tc-button {
  background-color: $ocean-blue;
  border-color: transparent;
  cursor: pointer;
  width: 100%;
  font-weight: 500;
  text-align: center;
  color: $white;

  &__xxs {
    font-size: 14px;
    height: 24px;
  }

  &__xs {
    font-size: 14px;
    height: 36px;
  }

  &__sm {
    font-size: 16px;
    height: 42px;
  }

  &__md {
    font-size: 18px;
    height: 48px;
  }

  &__lg {
    font-size: 20px;
    height: 54px;
  }

  &__mirror {
    border: none;
    border-radius: 0;
    text-transform: uppercase;
    background: linear-gradient(to left, #46afe9, #487ac2 46%, #254aa5);
  }

  &__primary {
    background-color: $orange;
  }

  &__secondary {
    background-color: $ocean-blue;
  }

  &__tertiary {
    background-color: $purple;
  }

  &__success {
    background-color: $toxic-green;
  }

  &__warning {
    background-color: $mango;
  }

  &__danger {
    background-color: $pinky-red;
  }

  &__round {
    border-radius: $border-radius;
  }

  &__square {
    border-radius: 0;
  }

  &:hover {
    filter: brightness(105%);
  }

  &:disabled,
  &[disabled] {
    filter: brightness(100%);
    cursor: no-drop;
    opacity: 0.6;
  }
}
</style>
