import { getOffer } from '@/api/offer'
import type { Offer } from '@/shared/interfaces/Offer'
import type { Package } from '@/shared/interfaces/Package'
import { defineStore } from 'pinia'
import getPackagesFromOffer from '@/shared/utils/getPackagesFromOffer'
import { computed, ref, type ComputedRef, type Ref } from 'vue'

const useOfferStore = defineStore('offer', () => {
  const offer: Ref<Offer[]> = ref([])

  const packages: ComputedRef<Package[]> = computed(() => {
    return getPackagesFromOffer(offer.value)
  })

  const setOffer = (value: Offer[]) => {
    offer.value = value
  }

  const fetchOffer = async () => {
    try {
      const response = await getOffer()

      offer.value = response.data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return {
    offer,
    packages,
    setOffer,
    fetchOffer
  }
})

export default useOfferStore
