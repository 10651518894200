import { redirectPromise } from '@/shared/utils/redirectToUrl'
import useOfferStore from '@/stores/offer'
import useTokenStore from '@/stores/token'
import Page from '@/shared/enums/Page'
import useAdsStore from '@/stores/ads'
import axios from 'axios'

const getBuyPackageFlowRoute = async () => {
  const adsStore = useAdsStore()
  const tokenStore = useTokenStore()
  const offerStore = useOfferStore()

  try {
    if (tokenStore.isProfiledToken) {
      if (tokenStore.decodedToken?.sourceMsisdn !== tokenStore.msisdn) {
        offerStore.setOffer([])

        await tokenStore.fetchBuyPackageToken()

        if (tokenStore.isCorpType) {
          adsStore.setShowCorpProfilesAdd(true)
        }
      }

      return { name: Page.Packages }
    }

    if (!tokenStore.msisdn || tokenStore.wifi) {
      return { name: Page.SendBalance }
    }

    offerStore.setOffer([])

    await tokenStore.fetchBuyPackageToken()

    if (tokenStore.isCorpType) {
      adsStore.setShowCorpProfilesAdd(true)
    }

    return { name: Page.Packages }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 406 || error.response?.status === 422) {
        return { name: Page.SendBalance }
      }

      if (error.response?.status === 403 && error.response.data?.redirectionUrl) {
        await redirectPromise(error.response.data?.redirectionUrl)
      }
    }

    return { name: Page.Error }
  }
}

export default getBuyPackageFlowRoute
