import Page from '@/shared/enums/Page'
import type { RouteRecordRaw } from 'vue-router'
import { ROUTES_GUARDS } from '@/router/guards'
import Home from '@/pages/Home.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: Page.Home,
    beforeEnter: ROUTES_GUARDS[Page.Home],
    component: Home
  },
  {
    path: '/send-balance',
    name: Page.SendBalance,
    beforeEnter: ROUTES_GUARDS[Page.SendBalance],
    component: () => import('@/pages/SendBalance.vue')
  },
  {
    path: '/request-recharge',
    name: Page.RequestBalance,
    beforeEnter: ROUTES_GUARDS[Page.RequestBalance],
    component: () => import('@/pages/RequestBalance.vue'),
    meta: { layout: false }
  },
  {
    path: '/balance',
    name: Page.Balance,
    beforeEnter: ROUTES_GUARDS[Page.Balance],
    component: () => import('@/pages/Balance.vue')
  },
  {
    path: '/packages',
    name: Page.Packages,
    beforeEnter: ROUTES_GUARDS[Page.Packages],
    component: () => import('@/pages/Packages/index.vue')
  },
  {
    path: '/packages/:externalID',
    name: Page.PackageDetails,
    beforeEnter: ROUTES_GUARDS[Page.PackageDetails],
    component: () => import('@/pages/Packages/[externalID].vue')
  },
  {
    path: '/orders',
    name: Page.Orders,
    beforeEnter: ROUTES_GUARDS[Page.Orders],
    component: () => import('@/pages/Orders/index.vue')
  },
  {
    path: '/orders/:folio',
    name: Page.OrderDetails,
    beforeEnter: ROUTES_GUARDS[Page.OrderDetails],
    component: () => import('@/pages/Orders/[folio].vue')
  },
  {
    path: '/help',
    name: Page.Help,
    beforeEnter: ROUTES_GUARDS[Page.Help],
    component: () => import('@/pages/Help.vue')
  },
  {
    path: '/terms-and-conditions',
    name: Page.TermsAndConditions,
    beforeEnter: ROUTES_GUARDS[Page.TermsAndConditions],
    component: () => import('@/pages/TermsAndConditions/index.vue')
  },
  {
    path: '/terms-and-conditions/:slug',
    name: Page.TermsAndConditionsDetails,
    beforeEnter: ROUTES_GUARDS[Page.TermsAndConditionsDetails],
    component: () => import('@/pages/TermsAndConditions/[slug].vue')
  },
  {
    path: '/error',
    name: Page.Error,
    beforeEnter: ROUTES_GUARDS[Page.Error],
    component: () => import('@/pages/Error.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

export default routes
