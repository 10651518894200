import { LOCAL_STORAGE_SKIP_HOME_KEY } from '@/shared/constants/general'
import Page from '@/shared/enums/Page'
import useAdsStore from '@/stores/ads'
import useTokenStore from '@/stores/token'
import type { NavigationGuard, NavigationGuardNext } from 'vue-router'

const redirectToPackagesFlow = async (next: NavigationGuardNext) => {
  const adsStore = useAdsStore()
  const tokenStore = useTokenStore()

  if (tokenStore.mobileViewer && (tokenStore.wifi || !tokenStore.msisdn)) {
    next({ name: Page.SendBalance })
    return
  }

  await tokenStore.fetchBuyPackageToken()

  if (tokenStore.isCorpType) {
    adsStore.setShowCorpProfilesAdd(true)
  }

  next({ name: Page.Packages })
}

const redirectToBalanceFlow = async (next: NavigationGuardNext) => {
  const adsStore = useAdsStore()
  const tokenStore = useTokenStore()

  if (tokenStore.wifi || !tokenStore.msisdn) {
    next()
    return
  }

  await tokenStore.fetchProfileCampaignToken()

  if (tokenStore.isCorpType) {
    adsStore.setShowCorpProfilesAdd(true)
  }

  next({ name: Page.Balance })
}

const localStorageSkipHomeGuard: NavigationGuard = async (to, from, next) => {
  const redirectPage = localStorage.getItem(LOCAL_STORAGE_SKIP_HOME_KEY)

  localStorage.removeItem(LOCAL_STORAGE_SKIP_HOME_KEY)

  try {
    switch (redirectPage) {
      case Page.Packages:
        await redirectToPackagesFlow(next)
        break
      case Page.Balance:
        await redirectToBalanceFlow(next)
        break
      default:
        next()
    }
  } catch (error) {
    next()
  }
}

export default localStorageSkipHomeGuard
