<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="49"
    height="16"
    viewBox="0 0 49 16"
  >
    <defs>
      <linearGradient
        id="imxe7o19ya"
        x1="-2.252"
        x2=".982"
        y1=".518"
        y2=".499"
        gradientUnits="objectBoundingBox"
      >
        <stop
          offset="0"
          stop-color="#262860"
        />
        <stop
          offset="1"
          stop-color="#3c429a"
        />
      </linearGradient>
      <linearGradient
        id="4qyrse9x7b"
        x1="-1.738"
        x2="2.08"
        y1=".511"
        y2=".492"
        xlink:href="#imxe7o19ya"
      />
      <linearGradient
        id="qp98bcar6c"
        x1=".147"
        x2="2.622"
        y1=".502"
        y2=".482"
        xlink:href="#imxe7o19ya"
      />
      <linearGradient
        id="g3k7ci4t3d"
        x1="-1.967"
        x2="4.38"
        y1=".508"
        y2=".488"
        xlink:href="#imxe7o19ya"
      />
    </defs>
    <g>
      <g>
        <path
          fill="url(#imxe7o19ya)"
          d="M148.36 16.486h-3.67c-.134-.647-.279-1.29-.392-1.914-.049-.258-.134-.369-.428-.364-1.473.018-2.946 0-4.418 0a.335.335 0 0 0-.379.273c-.2.609-.423 1.21-.65 1.805-.031.08-.134.191-.2.193H134.2c.258-.6.482-1.171.722-1.741L140.058 2.5a1.807 1.807 0 0 1 1.882-1.25h3.224c.106.5.214.988.315 1.478l2.528 12.1c.108.516.235 1.032.353 1.548zm-4.805-5.416c-.392-1.878-.774-3.727-1.163-5.579h-.046l-2.027 5.589z"
          transform="translate(-99.586 -.927)"
        />
        <path
          fill="url(#4qyrse9x7b)"
          d="M100.6.054c.31.036.616.085.916.15l.539.119c.436.1.867.242 1.331.374q-.353 1.646-.686 3.214c-.614-.181-1.2-.387-1.805-.516a5.133 5.133 0 0 0-2.495-.022 2.5 2.5 0 0 0-.939.493.856.856 0 0 0-.028 1.354 5.969 5.969 0 0 0 1.006.725c.8.482 1.648.9 2.427 1.419a3.58 3.58 0 0 1 1.731 2.579 4.576 4.576 0 0 1-2.167 4.687 7.436 7.436 0 0 1-3.495 1.13 12.494 12.494 0 0 1-5.1-.58c-.111-.036-.219-.08-.328-.126a.74.74 0 0 1-.111-.067l.712-3.33c.281.124.547.258.82.359a7.114 7.114 0 0 0 4.024.5 2.886 2.886 0 0 0 1.032-.431 1.1 1.1 0 0 0 .124-1.805 9.99 9.99 0 0 0-1.328-.872c-.709-.431-1.46-.8-2.13-1.29a3.56 3.56 0 0 1-1.6-2.538 4.333 4.333 0 0 1 1.688-4.01A7.9 7.9 0 0 1 100.6.054z"
          transform="translate(-67.818 .004)"
        />
        <path
          fill="url(#qp98bcar6c)"
          d="M18.5 1.236c-.206.5-.4.97-.6 1.442L12.226 16.2a.369.369 0 0 1-.415.258H8.23c-.258 0-.335-.085-.392-.312C6.827 12.208 5.8 8.272 4.8 4.331a2.079 2.079 0 0 0-1.241-1.522A19.716 19.716 0 0 0 .152 1.718L0 1.677c0-.451 0-.451.441-.451H6.7a1.805 1.805 0 0 1 1.894 1.547c.531 2.773 1.05 5.558 1.573 8.334.021.121.049.24.083.413a2.463 2.463 0 0 0 .116-.222q1.947-4.916 3.884-9.84a.315.315 0 0 1 .351-.237h3.632c.08.005.157.01.267.015z"
          transform="translate(0 -.902)"
        />
        <path
          fill="url(#g3k7ci4t3d)"
          d="M65.6 16.538c1.091-5.1 2.167-10.149 3.258-15.228h3.957l-.491 2.29q-1.341 6.283-2.68 12.571c-.062.291-.165.384-.467.377-1.083-.021-2.169 0-3.252 0z"
          transform="translate(-48.68 -.971)"
        />
      </g>
    </g>
  </svg>
</template>
