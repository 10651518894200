<script lang="ts" setup>
import { updateAdsConfig } from '@/api/ads'
import Modal from '@/components/Modal/index.vue'
import AdsEventType from '@/shared/enums/AdsEventType'
import { ref } from 'vue'

const open = ref(true)

const emits = defineEmits<{
  (e: 'next'): void
  (e: 'close'): void
}>()

const handleClickClose = () => {
  try {
    updateAdsConfig({ adsEventType: AdsEventType.CLOSE_CLICK })
  } catch (error) {
    //
  }
}

const handleClickNext = () => {
  try {
    updateAdsConfig({ adsEventType: AdsEventType.GIFT_CLICK })
    emits('next')
  } catch (error) {
    emits('close')
  }
}
</script>

<template>
  <Modal
    type="card"
    v-model:open="open"
    :show-backdrop="false"
    :before-close="() => emits('close')"
    :on-click-close="handleClickClose"
    class="hybrid-campaign-modal__button"
  >
    <div
      class="hybrid-campaign-modal__button-card"
      @click="handleClickNext"
    >
      <div class="hybrid-campaign-modal__button-banner">
        <img
          src="@/assets/img/hybrid-ads.png"
          alt="telcel"
        />

        <div class="triangle__top-right"></div>

        <div class="triangle__bottom-right"></div>
      </div>

      <p class="hybrid-campaign-modal__button-body">
        Conoce los <span>beneficios</span> y <span>promociones</span>
      </p>

      <img
        src="@/assets/img/svg/blue-arrows.svg"
        alt="telcel"
        height="25"
        width="25"
        class="hybrid-campaign-modal__button-arrows"
      />
    </div>
  </Modal>
</template>

<style lang="scss">
.hybrid-campaign-modal__button {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 20px;

  &-card {
    display: grid;
    align-items: center;
    grid-template-columns: 40% 60%;
    border: 2px solid $light-blue;
    border-radius: $border-radius;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  &-banner {
    position: relative;
    display: flex;
    margin-right: 8px;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: calc($border-radius - 2px) 0 0 calc($border-radius - 2px);
    }

    .triangle__top-right {
      position: absolute;
      right: 0;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid $white;
      border-left: 10px solid transparent;
    }

    .triangle__bottom-right {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-bottom: 50px solid $white;
      border-left: 10px solid transparent;
    }
  }

  &-body {
    font-size: 18px;

    & > span {
      font-style: italic;
      font-weight: 500;
    }
  }

  &-arrows {
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
}
</style>
