import { defineStore } from 'pinia'
import type { DecodedToken } from '@/shared/interfaces/DecodedToken'
import { getBuyPackageToken, getProfileCampaignToken, getSendPackageToken } from '@/api/token'
import { NULL_CAMPAIGN } from '@/shared/constants/campaigns'
import Profile from '@/shared/enums/Profile'
import getDecodedToken from '@/shared/utils/getDecodedToken'
import type { DecodedUrlToken } from '@/shared/interfaces/DecodedUrlToken'
import { ref, type ComputedRef, computed } from 'vue'

const useTokenStore = defineStore('token', () => {
  const urlToken = ref('')
  const token = ref('')

  const decodedUrlToken: ComputedRef<null | DecodedUrlToken> = computed(() => {
    return getDecodedToken<DecodedUrlToken>(urlToken.value)
  })

  const decodedToken: ComputedRef<null | DecodedToken> = computed(() => {
    return getDecodedToken<DecodedToken>(token.value)
  })

  const wifi: ComputedRef<boolean> = computed(() => {
    return Boolean(decodedUrlToken.value?.wifi)
  })

  const mobileViewer: ComputedRef<boolean> = computed(() => {
    return Boolean(decodedUrlToken.value?.mobileViewer)
  })

  const msisdn: ComputedRef<string> = computed(() => {
    return decodedToken.value?.msisdn || ''
  })

  const msisdnAreaCode: ComputedRef<string> = computed(() => {
    return (decodedToken.value?.msisdn || '').slice(2, 4)
  })

  const sourceMsisdn: ComputedRef<string> = computed(() => {
    return decodedToken.value?.sourceMsisdn || ''
  })

  const campaign: ComputedRef<string> = computed(() => {
    return (decodedToken.value?.cc || NULL_CAMPAIGN).toUpperCase()
  })

  const profileType: ComputedRef<Profile> = computed(() => {
    return Number(decodedToken.value?.profileType) || Profile.NONE
  })

  const isValidToken: ComputedRef<boolean> = computed(() => {
    if (!decodedToken.value) {
      return false
    }

    return decodedToken.value.exp * 1000 > new Date().getTime()
  })

  const isProfiledToken: ComputedRef<boolean> = computed(() => {
    if (!isValidToken.value) {
      return false
    }

    return Boolean(decodedToken.value?.profileType)
  })

  const isEmptyProfile: ComputedRef<boolean> = computed(() => {
    return profileType.value === Profile.NONE
  })

  const isPrepaidProfile: ComputedRef<boolean> = computed(() => {
    return profileType.value === Profile.PREPAID
  })

  const isPostpaidProfile: ComputedRef<boolean> = computed(() => {
    return profileType.value === Profile.POSTPAID
  })

  const isHybridProfile: ComputedRef<boolean> = computed(() => {
    return profileType.value === Profile.HYBRID
  })

  const isCorpProfile: ComputedRef<boolean> = computed(() => {
    return profileType.value === Profile.CORP
  })

  const isCorpPrepaidProfile: ComputedRef<boolean> = computed(() => {
    return profileType.value === Profile.CORP_PREPAID
  })

  const isCorpPostpaidProfile: ComputedRef<boolean> = computed(() => {
    return profileType.value === Profile.CORP_POSTPAID
  })

  const isCorpHybridProfile: ComputedRef<boolean> = computed(() => {
    return profileType.value === Profile.CORP_HYBRID
  })

  const isCorpType: ComputedRef<boolean> = computed(() => {
    return (
      isCorpProfile.value ||
      isCorpPrepaidProfile.value ||
      isCorpPostpaidProfile.value ||
      isCorpHybridProfile.value
    )
  })

  const setUrlToken = (newUrlToken: string) => {
    if (newUrlToken && getDecodedToken(newUrlToken)) {
      localStorage.setItem(import.meta.env.VITE_APP_URL_TOKEN_KEY, newUrlToken)
      urlToken.value = newUrlToken
    } else {
      localStorage.removeItem(import.meta.env.VITE_APP_URL_TOKEN_KEY)
      urlToken.value = ''
    }
  }

  const setToken = (newToken: string) => {
    if (newToken && getDecodedToken(newToken)) {
      localStorage.setItem(import.meta.env.VITE_APP_TOKEN_KEY, newToken)
      token.value = newToken
    } else {
      localStorage.removeItem(import.meta.env.VITE_APP_TOKEN_KEY)
      token.value = ''
    }
  }

  const fetchBuyPackageToken = async () => {
    try {
      setToken(urlToken.value)

      const response = await getBuyPackageToken()

      setToken(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const fetchSendPackageToken = async (msisdn: string) => {
    try {
      setToken(urlToken.value)

      const response = await getSendPackageToken(msisdn)

      setToken(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const fetchProfileCampaignToken = async () => {
    try {
      setToken(urlToken.value)

      const response = await getProfileCampaignToken()

      setToken(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return {
    urlToken,
    token,
    decodedUrlToken,
    decodedToken,
    wifi,
    mobileViewer,
    msisdn,
    msisdnAreaCode,
    sourceMsisdn,
    campaign,
    profileType,
    isValidToken,
    isProfiledToken,
    isEmptyProfile,
    isPrepaidProfile,
    isPostpaidProfile,
    isHybridProfile,
    isCorpProfile,
    isCorpPrepaidProfile,
    isCorpPostpaidProfile,
    isCorpHybridProfile,
    isCorpType,
    setUrlToken,
    setToken,
    fetchBuyPackageToken,
    fetchSendPackageToken,
    fetchProfileCampaignToken
  }
})

export default useTokenStore
