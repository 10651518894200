<script lang="ts" setup>
import { updateAdsConfig } from '@/api/ads'
import Button from '@/components/Button.vue'
import Modal from '@/components/Modal/index.vue'
import AdsEventType from '@/shared/enums/AdsEventType'
import { ref } from 'vue'

const open = ref(true)

const emits = defineEmits<{
  (e: 'close'): void
}>()

const handleClickClose = () => {
  try {
    updateAdsConfig({ adsEventType: AdsEventType.CLOSE_CLICK })
  } catch (error) {
    //
  }
}
</script>

<template>
  <Modal
    type="card"
    v-model:open="open"
    :before-close="() => emits('close')"
    :on-click-close="handleClickClose"
    class="hybrid-campaign-modal__success"
  >
    <div class="hybrid-campaign-modal__success-card">
      <div class="hybrid-campaign-modal__success-banner">
        <img
          src="@/assets/img/hybrid-ads.png"
          alt="telcel"
        />

        <div class="triangle__top-right"></div>

        <div class="triangle__bottom-right"></div>
      </div>

      <div class="hybrid-campaign-modal__success-body">
        <h3>Ya estas registrado.</h3>

        <p>En breve te enviaremos tus promociones.</p>

        <Button
          size="sm"
          color="secondary"
          @click="emits('close')"
        >
          Regresar
        </Button>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss">
.hybrid-campaign-modal__success {
  &-card {
    display: grid;
    align-items: center;
    grid-template-columns: 40% 60%;
    border: 2px solid $light-blue;
    border-radius: $border-radius;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  &-banner {
    position: relative;
    display: flex;
    height: 140px;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: calc($border-radius - 2px) 0 0 calc($border-radius - 2px);
    }

    .triangle__top-right {
      position: absolute;
      right: 0;
      top: 0;
      width: 0;
      height: 0;
      border-top: 70px solid $white;
      border-left: 20px solid transparent;
    }

    .triangle__bottom-right {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-bottom: 70px solid $white;
      border-left: 20px solid transparent;
    }
  }

  &-body {
    text-align: center;
    font-size: 15px;

    h3 {
      margin: 4px 0;
    }

    p {
      margin: 0;
      margin-bottom: 8px;
    }

    button {
      max-width: 180px;
    }
  }
}
</style>
