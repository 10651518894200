import Page from '@/shared/enums/Page'
import useTokenStore from '@/stores/token'
import type { NavigationGuard } from 'vue-router'

const zeroCostGuard: NavigationGuard = (to, from, next) => {
  const tokenStore = useTokenStore()

  if (tokenStore.wifi) {
    next({ name: Page.Home })
    return
  }

  next()
}

export default zeroCostGuard
