enum Profile {
  NONE = 0,
  PREPAID = 1,
  POSTPAID = 2,
  HYBRID = 3,
  CORP = 4,
  CORP_PREPAID = 5,
  CORP_POSTPAID = 6,
  CORP_HYBRID = 7
}

export default Profile
