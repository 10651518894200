<script lang="ts" setup>
import DefaultHeader from '@/components/layout/DefaultHeader.vue'
import MirrorHeader from '@/components/layout/MirrorHeader.vue'
import Loading from '@/components/Loading.vue'
import useAppStore from '@/stores/app'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const appStore = useAppStore()
const route = useRoute()

const hideHeader = computed(() => {
  return !route.name || route.meta.layout === false
})
</script>

<template>
  <Loading v-if="appStore.loading" />

  <template v-if="!hideHeader">
    <DefaultHeader v-if="appStore.isDefaultChannel" />
    <MirrorHeader v-else />
  </template>

  <div class="tc-app__container">
    <RouterView />
  </div>
</template>

<style lang="scss">
.tc-app__container {
  width: 100%;
  max-width: $screen-sm;
  margin: 0 auto;
}
</style>
