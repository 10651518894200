enum Page {
  Home = 'Home',
  Balance = 'Balance',
  SendBalance = 'SendBalance',
  RequestBalance = 'RequestBalance',
  Packages = 'Packages',
  PackageDetails = 'PackageDetails',
  Orders = 'Orders',
  OrderDetails = 'OrderDetails',
  Help = 'Help',
  TermsAndConditions = 'TermsAndConditions',
  TermsAndConditionsDetails = 'TermsAndConditionsDetails',
  Error = 'Error'
}

export default Page
