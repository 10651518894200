<script lang="ts">
// https://vuejs.org/guide/components/attrs.html#disabling-attribute-inheritance
export default {
  inheritAttrs: false
}
</script>

<script lang="ts" setup>
import type { Size } from '@/shared/types/Size'
import Backdrop from '@/components/Backdrop.vue'
import ModalCard from '@/components/Modal/ModalCard.vue'

export type ModalType = 'default' | 'card'

export interface Props {
  open: boolean
  type?: ModalType
  size?: Size
  showClose?: boolean
  showBackdrop?: boolean
  beforeClose?: () => void
  onClickClose?: () => void
}

const props = withDefaults(defineProps<Props>(), {
  type: 'default',
  size: 'xxs',
  showClose: true,
  showBackdrop: true
})

const emits = defineEmits<{
  (e: 'update:open', value: false): void
}>()

const handleClose = () => {
  if (typeof props.beforeClose === 'function') {
    props.beforeClose()
  } else {
    emits('update:open', false)
  }
}

const handleClickClose = () => {
  if (typeof props.onClickClose === 'function') {
    props.onClickClose()
  }

  handleClose()
}
</script>

<template>
  <Teleport to="body">
    <template v-if="showBackdrop">
      <Backdrop
        v-if="open"
        v-bind="$attrs"
        @click.self="handleClose"
      >
        <ModalCard
          v-if="type === 'card'"
          :size="size"
          @close="handleClickClose"
        >
          <slot></slot>
        </ModalCard>

        <slot v-else></slot>
      </Backdrop>
    </template>

    <div
      v-else
      v-bind="$attrs"
    >
      <ModalCard
        v-if="type === 'card'"
        :size="size"
        @close="handleClickClose"
      >
        <slot></slot>
      </ModalCard>

      <slot v-else></slot>
    </div>
  </Teleport>
</template>
