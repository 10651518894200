import { defineStore } from 'pinia'
import { ref, type ComputedRef, type Ref, computed } from 'vue'
import type { SeigyoFrontEndConfiguration } from '@/shared/interfaces/SeigyoFrontEndConfiguration'
import type { Maintenance } from '@/shared/interfaces/Maintenance'
import MaintenanceType from '@/shared/enums/MaintenanceType'
import { getSeigyoConfig, getSeigyoInvoicesConfig } from '@/api/seigyo'
import type { AdvertisingBanner } from '@/shared/interfaces/AdvertisingBanner'
import useTokenStore from './token'
import AdvertisingBannerType from '@/shared/enums/AdvertisingBannerType'
import Page from '@/shared/enums/Page'

const useSeigyoStore = defineStore('seigyo', () => {
  const config: Ref<null | SeigyoFrontEndConfiguration> = ref(null)
  const invoicesConfig: Ref<null | SeigyoFrontEndConfiguration> = ref(null)

  const banners: ComputedRef<AdvertisingBanner[]> = computed(() => {
    return config.value?.AdvertisingBanners || []
  })

  const enabledBanners: ComputedRef<AdvertisingBanner[]> = computed(() => {
    return banners.value.filter((banner) => banner.IsAvailable)
  })

  const profileBanners: ComputedRef<AdvertisingBanner[]> = computed(() => {
    const tokenStore = useTokenStore()

    return enabledBanners.value.filter((banner) => banner.Profiles.includes(tokenStore.profileType))
  })

  const homeBanners: ComputedRef<AdvertisingBanner[]> = computed(() => {
    return enabledBanners.value.filter((banner) => banner.Page === Page.Home)
  })

  const packagesBanners: ComputedRef<AdvertisingBanner[]> = computed(() => {
    return profileBanners.value.filter((banner) => banner.Page === Page.Packages)
  })

  const orderDetailsBanners: ComputedRef<AdvertisingBanner[]> = computed(() => {
    return profileBanners.value.filter((banner) => banner.Page === Page.OrderDetails)
  })

  const packagesCarouselBanners: ComputedRef<AdvertisingBanner[]> = computed(() => {
    return packagesBanners.value
      .filter((banner) => banner.Type === AdvertisingBannerType.CAROUSEL)
      .sort((a, b) => a.Position - b.Position)
  })

  const homeModalBanner: ComputedRef<undefined | AdvertisingBanner> = computed(() => {
    const tokenStore = useTokenStore()

    return homeBanners.value.find(
      (banner) =>
        banner.Type === AdvertisingBannerType.STANDARD &&
        banner.Section === 'modal' &&
        tokenStore.campaign === banner.Campaign
    )
  })

  const packagesModalBanner: ComputedRef<undefined | AdvertisingBanner> = computed(() => {
    const tokenStore = useTokenStore()

    return packagesBanners.value.find(
      (banner) =>
        banner.Type === AdvertisingBannerType.STANDARD &&
        banner.Section === 'modal' &&
        tokenStore.campaign === banner.Campaign
    )
  })

  const packagesFooterBanner: ComputedRef<undefined | AdvertisingBanner> = computed(() => {
    return packagesBanners.value.find(
      (banner) => banner.Type === AdvertisingBannerType.STANDARD && banner.Section === 'footer'
    )
  })

  const orderDetailsFooterBanner: ComputedRef<undefined | AdvertisingBanner> = computed(() => {
    return orderDetailsBanners.value.find(
      (banner) => banner.Type === AdvertisingBannerType.STANDARD && banner.Section === 'footer'
    )
  })

  const maintenance: ComputedRef<undefined | Maintenance> = computed(() => {
    return config.value?.Maintenance[0]
  })

  const invoicesMaintenance: ComputedRef<undefined | Maintenance> = computed(() => {
    return invoicesConfig.value?.Maintenance[0]
  })

  const isMaintenance: ComputedRef<boolean> = computed(() => {
    return Boolean(maintenance.value?.MaintenanceModeOn)
  })

  const isInvoicesMaintenance: ComputedRef<boolean> = computed(() => {
    return Boolean(invoicesMaintenance.value?.MaintenanceModeOn)
  })

  const isGlobalMaintenance: ComputedRef<boolean> = computed(() => {
    return isMaintenance.value && maintenance.value?.Type === MaintenanceType.global
  })

  const isPartialMaintenance: ComputedRef<boolean> = computed(() => {
    return isMaintenance.value && maintenance.value?.Type === MaintenanceType.partial
  })

  const partialMaintenanceEndTimeString: ComputedRef<string> = computed(() => {
    if (!maintenance.value?.EndDate) return ''

    const date = new Date(maintenance.value.EndDate)
    const hours = date.getHours().toString()
    let minutes = date.getMinutes().toString()

    if (minutes.length < 2) {
      minutes = `0${minutes}`
    }

    return `${hours}:${minutes}`
  })

  const invoicesPartialMaintenanceEndTimeString: ComputedRef<string> = computed(() => {
    if (!invoicesMaintenance.value?.EndDate) return ''

    const date = new Date(invoicesMaintenance.value.EndDate)
    const hours = date.getHours().toString()
    let minutes = date.getMinutes().toString()

    if (minutes.length < 2) {
      minutes = `0${minutes}`
    }

    return `${hours}:${minutes}`
  })

  const fetchConfig = async () => {
    try {
      const response = await getSeigyoConfig()

      config.value = response
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const fetchInvoicesConfig = async () => {
    try {
      const response = await getSeigyoInvoicesConfig()

      invoicesConfig.value = response
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return {
    config,
    invoicesConfig,
    profileBanners,
    homeBanners,
    packagesBanners,
    orderDetailsBanners,
    packagesCarouselBanners,
    homeModalBanner,
    packagesModalBanner,
    packagesFooterBanner,
    orderDetailsFooterBanner,
    maintenance,
    invoicesMaintenance,
    isMaintenance,
    isInvoicesMaintenance,
    isGlobalMaintenance,
    isPartialMaintenance,
    partialMaintenanceEndTimeString,
    invoicesPartialMaintenanceEndTimeString,
    fetchConfig,
    fetchInvoicesConfig
  }
})

export default useSeigyoStore
