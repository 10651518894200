import type { Offer } from '@/shared/interfaces/Offer'

const getPackagesFromOffer = (offer: Offer[]) => {
  const results: Set<string> = new Set()

  offer.forEach((offer) => {
    offer.sliders.forEach((slider) => {
      slider.packages.forEach((pack) => {
        results.add(JSON.stringify(pack))
      })
    })
  })

  return [...results].map((e) => JSON.parse(e))
}

export default getPackagesFromOffer
