<script lang="ts" setup>
import Button from '@/components/Button.vue'
import { updateAdsConfig } from '@/api/ads'
import AdsEventType from '@/shared/enums/AdsEventType'
import Modal from '@/components/Modal/index.vue'
import { ref } from 'vue'

const open = ref(true)
const emits = defineEmits<{
  (e: 'next'): void
  (e: 'close'): void
}>()

const handleClickClose = () => {
  try {
    updateAdsConfig({ adsEventType: AdsEventType.CLOSE_CLICK })
  } catch (error) {
    //
  }
}

const handleClickDeny = () => {
  try {
    updateAdsConfig({ adsEventType: AdsEventType.DENIED_CLICK })
  } catch (error) {
    //
  }

  emits('close')
}

const handleClickNext = () => {
  try {
    updateAdsConfig({ adsEventType: AdsEventType.AGREEMENT_CLICK })
    emits('next')
  } catch (error) {
    emits('close')
  }
}
</script>

<template>
  <Modal
    type="card"
    v-model:open="open"
    :before-close="() => emits('close')"
    :on-click-close="handleClickClose"
    class="hybrid-campaign-modal__content"
  >
    <img
      src="@/assets/img/hybrid-ads.png"
      alt="telcel ads"
      class="hybrid-campaign-modal__content-header"
    />

    <div class="hybrid-campaign-modal__content-body">
      <h3 class="hybrid-campaign-modal__content-title">
        ¿Quieres conocer las promociones que Telcel tiene para ti?
      </h3>

      <p>
        Autorizo que Telcel utilice mi información y/o datos personales con fines publicitarios y/o
        mercadológicos ofrecidos por Telcel y/o terceros, de conformidad con lo establecido con el
        Aviso de Privacidad.
      </p>

      <a
        href="https://www.telcel.com/aviso-de-privacidad"
        aria-label="Ir a aviso de privacidad"
        rel="noopener noreferrer"
        target="_blank"
      >
        www.telcel.com/aviso-de-privacidad
      </a>

      <p>En cualquier momento podrás darte de baja en el canal de autogestión de Mi Telcel.</p>
    </div>

    <div class="hybrid-campaign-modal__content-footer">
      <Button
        size="sm"
        class="hybrid-campaign-modal__content-info-button"
        @click="handleClickDeny"
      >
        De momento no
      </Button>

      <Button
        size="sm"
        color="secondary"
        @click="handleClickNext"
      >
        De acuerdo
      </Button>
    </div>
  </Modal>
</template>

<style lang="scss">
.hybrid-campaign-modal__content {
  .tc-card {
    text-align: center;
    border: 2px solid $light-blue;
    border-radius: $border-radius;
  }

  &-header {
    height: 70px;
    width: 100%;
    object-fit: cover;
    border-radius: calc($border-radius - 1px) calc($border-radius - 1px) 0 0;
  }

  &-title {
    font-size: 18px;
    margin: 0;
  }

  &-body {
    font-size: 14px;
    padding: 18px;
  }

  &-info-button {
    border: 2px solid $ocean-blue;
    background-color: $white !important;
    color: $ocean-blue;
  }

  &-footer {
    padding: 0 18px 18px;
    display: flex;

    button + button {
      margin-left: 10px;
    }
  }
}
</style>
@/shared/enums/AdType
