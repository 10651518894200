import useAppStore from '@/stores/app'
import requestInstance from '@/shared/utils/requestInstance'
import type { AxiosRequestConfig } from 'axios'

const request = async (config: AxiosRequestConfig, loader = true) => {
  const appStore = useAppStore()

  if (loader) {
    appStore.setLoading(true)
  }

  try {
    const response = await requestInstance(config)
    return response
  } catch (error) {
    return Promise.reject(error)
  } finally {
    if (loader) {
      appStore.setLoading(false)
    }
  }
}

export default request
