import CheckoutStep from '@/shared/enums/CheckoutStep'
import Page from '@/shared/enums/Page'
import PaymentMethod from '@/shared/enums/PaymentMethod'
import getPaymentId from '@/shared/utils/getPaymentId'
import useCheckoutStore from '@/stores/checkout'
import useTokenStore from '@/stores/token'
import type { NavigationGuard } from 'vue-router'

const packageDetailsPaymentMethodQueryGuard: NavigationGuard = async (to, from, next) => {
  const paymentMethod = Number(to.query.paymentMethod) as PaymentMethod

  if (!paymentMethod) {
    next()
    return
  }

  const checkoutStore = useCheckoutStore()
  const paymentID = getPaymentId(paymentMethod)

  if (!checkoutStore.package?.paymentMethodsAllowed.includes(paymentMethod) || !paymentID) {
    next({ name: Page.Packages })
    return
  }

  if (paymentMethod === PaymentMethod.CreditCard) {
    checkoutStore.setStep(CheckoutStep.PREVIEW)
  } else {
    checkoutStore.setStep(CheckoutStep.CONFIRM)
  }

  const tokenStore = useTokenStore()
  const targetMsisdn = tokenStore.decodedToken?.msisdn as string
  const packageID = checkoutStore.package.id

  try {
    await checkoutStore.fetchSwitchOrder({
      targetMsisdn,
      packageID,
      paymentID
    })

    next({ ...to, query: {} })
  } catch (error) {
    next({ name: Page.Error })
  }
}

export default packageDetailsPaymentMethodQueryGuard
