import Page from '@/shared/enums/Page'
import useCheckoutStore from '@/stores/checkout'
import type { NavigationGuard } from 'vue-router'

const packageDetailsExternalIDParamGuard: NavigationGuard = async (to, from, next) => {
  const externalID = to.params.externalID

  if (!externalID) {
    next({ name: Page.Packages })
  }

  const checkoutStore = useCheckoutStore()
  const offerPackage = checkoutStore.getPackageByExternalID(externalID as string)

  if (!offerPackage) {
    next({ name: Page.Packages })
    return
  }

  checkoutStore.setPackage(offerPackage)

  next()
}

export default packageDetailsExternalIDParamGuard
