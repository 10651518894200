<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="94.422"
    height="26.871"
    viewBox="0 0 94.422 26.871"
  >
    <g data-name="Grupo 6199">
      <path
        data-name="Trazado 4297"
        d="M66.791 46.037c-2.132-.307-1.356-2.144-.031-4.531.411.028 1.949.047 1.949.047h10.562s.625-1.126 2.275-4.2c2.749-5.128-.295-8.164-5.513-8.225-7.466-.088-9.985 3.5-12.646 8.182a38.314 38.314 0 0 0-2.922 6.16 4.842 4.842 0 0 0 .782 4.991 5.589 5.589 0 0 0 3.8 1.721 61.857 61.857 0 0 0 8.865-.22l2.161-4.075s-6.743.521-9.286.155m8.146-12.85c1.425 0 3.242.544 2.163 2.548a82.33 82.33 0 0 0-1.079 2.063h-7.48c1.937-3.549 3.562-4.612 6.4-4.612"
        transform="translate(-48.308 -23.4)"
        style="fill: #f7f7fb"
      />
      <path
        data-name="Trazado 4298"
        d="M301.941 46.037c-2.132-.307-1.356-2.144-.031-4.531.411.028 1.949.047 1.949.047h10.563s.625-1.126 2.275-4.2c2.749-5.128-.295-8.164-5.513-8.225-7.466-.088-9.985 3.5-12.646 8.182a38.314 38.314 0 0 0-2.922 6.16 4.841 4.841 0 0 0 .782 4.991 5.589 5.589 0 0 0 3.8 1.721 61.857 61.857 0 0 0 8.865-.22l2.161-4.075s-6.743.521-9.286.155m8.146-12.85c1.424 0 3.242.544 2.163 2.548a82.33 82.33 0 0 0-1.079 2.063h-7.48c1.937-3.549 3.562-4.612 6.4-4.612"
        transform="translate(-237.255 -23.4)"
        style="fill: #f7f7fb"
      />
      <path
        data-name="Trazado 4299"
        d="m395.3.006-14.07 26.62h5.506L400.745 0z"
        transform="translate(-306.323)"
        style="fill: #f7f7fb"
      />
      <path
        data-name="Trazado 4300"
        d="M228.339 29.377a51.984 51.984 0 0 0-6.056-.283 18.348 18.348 0 0 0-5.207.7c-4.482 1.428-6.2 4.79-7.683 7.49a35.05 35.05 0 0 0-2.922 6.16c-1.452 4.771 2.3 6.537 4.584 6.712a53.769 53.769 0 0 0 8.15-.22l2.132-4.063a54.8 54.8 0 0 1-8.447.191c-.886-.124-1.265-.586-1.309-1.25a7.523 7.523 0 0 1 1.146-3.281c.705-1.426 2.163-3.953 2.163-3.953a10.438 10.438 0 0 1 2.2-2.882c1.379-1.124 2.9-1.356 5.162-1.346 1.766.008 3.847.25 3.847.25l2.24-4.219z"
        transform="translate(-165.641 -23.374)"
        style="fill: #f7f7fb"
      />
      <path
        data-name="Trazado 4301"
        d="M13.269 5.919 16.388.03h-5.456L7.821 5.919h-3.7L1.967 10l2.012-.008L5.7 9.935s-2.142 4.236-3.727 7.278C.7 19.657-.072 21.333 0 23.029a3.767 3.767 0 0 0 1 2.48c1.633 1.625 4.311 1.462 6.824 1.265l2.22-4.189a20.121 20.121 0 0 1-3.226-.018c-1.454-.21-1.733-.849-1.161-2 1.144-2.4 5.47-10.6 5.47-10.6l1.843.059h2.307l2.171-4.1h-4.181z"
        transform="translate(.004 -.024)"
        style="fill: #f7f7fb"
      />
      <path
        data-name="Trazado 4302"
        d="m162.182.006-14.072 26.62h5.506L167.625 0z"
        transform="translate(-119.008)"
        style="fill: #f7f7fb"
      />
    </g>
  </svg>
</template>
