import { defineStore } from 'pinia'
import { ref } from 'vue'

const useAdsStore = defineStore('ads', () => {
  const showMtsCampaignAdd = ref(false)
  const showHybridCampaignAdd = ref(false)
  const showCorpProfilesAdd = ref(false)

  const setShowMtsCampaignAdd = (value: boolean) => {
    showMtsCampaignAdd.value = value
  }

  const setShowHybridCampaignAdd = (value: boolean) => {
    showHybridCampaignAdd.value = value
  }

  const setShowCorpProfilesAdd = (value: boolean) => {
    showCorpProfilesAdd.value = value
  }

  return {
    showMtsCampaignAdd,
    showHybridCampaignAdd,
    showCorpProfilesAdd,
    setShowMtsCampaignAdd,
    setShowHybridCampaignAdd,
    setShowCorpProfilesAdd
  }
})

export default useAdsStore
