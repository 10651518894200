import type { SeigyoFrontEndConfiguration } from '@/shared/interfaces/SeigyoFrontEndConfiguration'

export const getSeigyoConfig = async (): Promise<SeigyoFrontEndConfiguration> => {
  const url = import.meta.env.VITE_APP_SEIGYO_URL as string
  const response = await fetch(url)

  return response.json()
}

export const getSeigyoInvoicesConfig = async (): Promise<SeigyoFrontEndConfiguration> => {
  const url = import.meta.env.VITE_APP_INVOICES_SEIGYO_URL as string
  const response = await fetch(url)

  return response.json()
}
