import type { RequestBalancePayload } from '@/shared/interfaces/RequestBalancePayload'
import request from '@/shared/utils/request'
import type { AxiosPromise } from 'axios'

export const getBuyPackageToken = (): AxiosPromise<string> => {
  return request({
    url: '/eiko-profile/profile/buyPackage',
    method: 'GET'
  })
}

export const getSendPackageToken = (msisdn: string): AxiosPromise<string> => {
  return request({
    url: '/eiko-profile/profile/sendPackage',
    data: `52${msisdn}`,
    method: 'POST'
  })
}

export const getProfileCampaignToken = (): AxiosPromise<string> => {
  return request({
    url: '/eiko-profile/profile/profileCampaign',
    method: 'GET'
  })
}

export const getRequestBalanceToken = (data: RequestBalancePayload) => {
  return request({
    url: '/eiko-profile/confirmationLink/externalPurchase',
    method: 'POST',
    data
  })
}
