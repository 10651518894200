import { regenerateTokenPromise } from '@/shared/utils/regenerateToken'
import useTokenStore from '@/stores/token'
import type { NavigationGuard } from 'vue-router'

const localStorageTokenGuard: NavigationGuard = async (to, from, next) => {
  const tokenStore = useTokenStore()

  if (!tokenStore.token) {
    const token = localStorage.getItem(import.meta.env.VITE_APP_TOKEN_KEY)

    if (token) {
      tokenStore.setToken(token as string)
    } else {
      await regenerateTokenPromise()
    }
  }

  next()
}

export default localStorageTokenGuard
