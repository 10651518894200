<script lang="ts" setup>
import HomeCard from '@/components/pages/Home/HomeCard.vue'
import Page from '@/shared/enums/Page'
import getBuyPackageFlowRoute from '@/shared/utils/getBuyPackageFlowRoute'
import { redirectToInvoicesPromise } from '@/shared/utils/redirectToInvoices'
import useSeigyoStore from '@/stores/seigyo'
import { useRouter } from 'vue-router'
import HomeCardMaintenance from '@/components/pages/Home/HomeCardMaintenance.vue'

const seigyoStore = useSeigyoStore()
const router = useRouter()

const handleClickBuyPackage = async () => {
  if (!seigyoStore.isMaintenance) {
    const route = await getBuyPackageFlowRoute()
    router.push(route)
  }
}

const handleClickSendBalance = async () => {
  if (!seigyoStore.isMaintenance) {
    router.push({ name: Page.SendBalance })
  }
}

const handleClickInvoices = async () => {
  if (!seigyoStore.isInvoicesMaintenance) {
    await redirectToInvoicesPromise()
  }
}
</script>

<template>
  <div class="tc-home__cards-grid">
    <HomeCard
      title="Recargar Saldo y Paquetes"
      subtitle="(Mi teléfono)"
      help="(La navegación en el portal es gratuita)"
      button-text="Recargar"
      :show-maintenance="!seigyoStore.isGlobalMaintenance && seigyoStore.isPartialMaintenance"
      :maintenance-end-time="seigyoStore.partialMaintenanceEndTimeString"
      class="tc-home__cards-grid-item"
      show-icons
      @click="handleClickBuyPackage"
    />

    <HomeCard
      title="Enviar Saldo a familiares y amigos"
      help="(Se requiere WiFi o Datos Móviles)"
      button-text="Enviar"
      class="tc-home__cards-grid-item"
      aria-label="Ir a envío de saldo"
      :show-maintenance="!seigyoStore.isGlobalMaintenance && seigyoStore.isPartialMaintenance"
      :maintenance-end-time="seigyoStore.partialMaintenanceEndTimeString"
      @click="handleClickSendBalance"
    />

    <HomeCard
      title="Pagar mis facturas"
      button-text="Pagar"
      class="tc-home__cards-grid-item"
      aria-label="Ir a pago de facturas"
      :show-maintenance="!seigyoStore.isGlobalMaintenance && seigyoStore.isInvoicesMaintenance"
      :maintenance-end-time="seigyoStore.invoicesPartialMaintenanceEndTimeString"
      @click="handleClickInvoices"
    />

    <HomeCardMaintenance
      v-if="seigyoStore.isGlobalMaintenance"
      :maintenance-end-time="
        seigyoStore.partialMaintenanceEndTimeString ||
        seigyoStore.invoicesPartialMaintenanceEndTimeString
      "
    />
  </div>
</template>

<style lang="scss">
.tc-home {
  &__cards-grid {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 18px;
    position: relative;
  }

  &__cards-grid-item:first-child {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
</style>
